import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import AclAction from "../Components/ACL/AclAction";
import CustomDatePicker from "../Components/CustomDatePicker";
import CustomDropdownList from "../Components/CustomDropdownList";
import CustomMultiSelect from "../Components/CustomMultiSelect";
import DailogNew from "../Components/DailogNew";
import scrollIntoErrors from "../Components/errorScrolling";
import Notification from "../Components/Notification";
import { BenchmarkServices } from "../Services/Bechmark";
import { ClientServices } from "../Services/Client";
import { CompanyServices } from "../Services/Company";
import { MarketForecastServices } from "../Services/MarketForecast";
import { companies } from "../Store/Actions/CompanyDetails";
import constants from "../Utils/constants";
import CustomCloneDeep from "../Utils/CustomCloneDeep";
import DocumentTitle from "../Utils/DocumentTitle";
import IsTBRIClient from "../Utils/IsTBRIClient";
import Toast from "../Utils/Toast";
import { MarketLandscapeServices } from "../Services/MarketLandscape";
import { SpecialReportServices } from "../Services/SpecialReport";
import { CustomerStudyServices } from "../Services/CustomerStudy";
import { EcosystemServices } from "../Services/Ecosystem";
import { isEmpty } from "lodash";

class ManageClient extends Component {
  state = {
    clientName: "",
    contactPersonName: "",
    contactEmail: "",
    contactNumber: "",
    website: "",
    address: "",
    startDate: null,
    endDate: null,
    isAllowLifetime: false,
    noOfTotalUsers: 0,
    isUserCountUnlimited: false,
    isAllCompany: false,
    isAllSegment: false,
    isAllBenchmark: false,
    isAllBenchmarkSegment: false,
    isAllMarketForecast: false,
    isAllMarketForecastSegment: false,
    unselectedsegmentList: [],
    unselectedmarketforecastList: [],
    selectedMarketForecastList: [],
    unselectedBenchMarkList: [],
    segmentList: [],
    segmentCompanies: [],
    benchmarkSegmentsList: [],
    benchmarkList: [],
    selectedBenchMarkList: [],
    selectedBenchmarks: [],
    marketForecastSegmentsList: [],
    marketForecastList: [],
    selectedMarketForecasts: [],
    formErrors: {},
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    isFormSubmitted: false,
    apiError: null,
    isTBRClient: false,
    isAllMarketLandscape: false,
    isAllMarketLandscapeSegment: false,
    marketLandscapeSegmentsList: [],
    marketLandscapeList: [],
    selectedMarketLandscapes: [],
    MaximumUsers: 0,
    unselectedMarketLandScapesList: [],
    selectedMarketLandScapesList: [],

    specialReportsList: [],
    unselectedSpecialReportsList: [],
    selectedSpecialReportsList: [],
    specialReportsSegmentsList: [],
    selectedSpecialReports: [],
    isAllSpecialReportsSegment: false,
    isAllSpecialReports: false,

    customerStudiesList: [],
    unselectedCustomerStudiesList: [],
    selectedCustomerStudiesList: [],
    customerStudiesSegmentsList: [],
    selectedCustomerStudies: [],
    isAllCustomerStudiesSegment: false,
    isAllCustomerStudies: false,

    ecosystemList: [],
    unselectedEcosystemList: [],
    selectedEcosystemList: [],
    ecosystemSegmentsList: [],
    selectedEcosystems: [],
    isAllEcosystemSegment: false,
    isAllEcosystem: false,
    is_set_password: 1,
    is_sso: 0,
  };
  async componentDidMount() {
    let clientId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    DocumentTitle(
      clientId === null
        ? constants.APPLICATION_ROUTE.CLIENT.CREATE.HEADER_TITLE
        : constants.APPLICATION_ROUTE.CLIENT.EDIT.HEADER_TITLE
    );
    let totalCompanies = [];
    await ClientServices.getSegmentDetails(1)
      .then(async (res) => {
        let segmentsResponse = res && res.data && res.data.data;
        CompanyServices.getTotalCompanies("companies")
          .then((res) => {
            let companyResponse = res && res.data && res.data.data;

            companyResponse.forEach((com) => {
              com.company_segment_id = com.id;
            });
            totalCompanies.push(...companyResponse);
            let segmentList = [];
            segmentList.push({
              uid: Math.random(),
              id: "all",
              name: "All",
              companies: totalCompanies,
            });
            segmentsResponse.forEach((seg) => {
              seg.companies.forEach((com) => {
                com.company_segment_id = com.company_segment
                  ? com.company_segment.id
                  : "-";
              });
            });
            segmentList = [...segmentList, ...segmentsResponse];
            const segmentWithoutER = segmentList.filter(
              (r) => !r.name.includes("Earnings Response")
            );
            this.setState({
              segmentList: segmentList,
              benchmarkSegmentsList: CustomCloneDeep(segmentWithoutER),
              marketForecastSegmentsList: CustomCloneDeep(segmentWithoutER),
              marketLandscapeSegmentsList: CustomCloneDeep(segmentWithoutER),
              specialReportsSegmentsList: CustomCloneDeep(segmentWithoutER),
              customerStudiesSegmentsList: CustomCloneDeep(segmentWithoutER),
              ecosystemSegmentsList: CustomCloneDeep(segmentWithoutER),
            });
            this.setState({ unselectedsegmentList: this.state.segmentList });
          })
          .catch((error) => {
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          })
          .finally(() =>
            document.body.classList.remove("second-loading-indicator")
          );
        await BenchmarkServices.getTotalBenchmarks("benchmarks")
          .then((res) => {
            let benchmarkList = res && res.data && res.data.data;
            this.setState({
              benchmarkList: benchmarkList,
              unselectedBenchMarkList: benchmarkList,
            });
          })
          .catch((error) => {
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          });
        await MarketForecastServices.getTotalMarketForecasts("marketforecasts")
          .then((res) => {
            let marketForecastList = res && res.data && res.data.data;
            this.setState({
              marketForecastList: marketForecastList,
              unselectedmarketforecastList: marketForecastList,
            });
          })
          .catch((error) => {
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          });
        await MarketLandscapeServices.getTotalMarketLandscapes(
          "marketlandscapes"
        )
          .then((res) => {
            let marketLandscapeList = res && res.data && res.data.data;
            this.setState({
              marketLandscapeList: marketLandscapeList,
              unselectedMarketLandScapesList: marketLandscapeList,
            });
          })
          .catch((error) => {
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          });
        await SpecialReportServices.getTotalSpecialReports("specialreports")
          .then((res) => {
            let specialReportsList = res && res.data && res.data.data;
            this.setState({
              specialReportsList: specialReportsList,
              unselectedSpecialReportsList: specialReportsList,
            });
          })
          .catch((error) => {
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          });
        await CustomerStudyServices.getTotalCustomerStudies(
          "customer-study/list"
        )
          .then((res) => {
            let customerStudiesList = res && res.data && res.data.data;
            this.setState({
              customerStudiesList: customerStudiesList,
              unselectedCustomerStudiesList: customerStudiesList,
            });
          })
          .catch((error) => {
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          });
        await EcosystemServices.getTotalEcosystem("ecosystem/list")
          .then((res) => {
            let ecosystemList = res && res.data && res.data.data;
            this.setState({
              ecosystemList: ecosystemList,
              unselectedEcosystemList: ecosystemList,
            });
          })
          .catch((error) => {
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          });

        if (clientId !== null) this.prepopulateData(clientId);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }
  prepopulateData = async (clientId) => {
    ClientServices.getClientDetails(clientId)
      .then((res) => {
        let response = res.data;
        let segmentCompanies = [];
        response.company_segment.forEach((s) => {
          segmentCompanies.push({
            uid: Math.random(),
            id: s.id,
            name: s.name,
            companies:
              (s.companies &&
                s.companies.map((c) => {
                  return { id: c.company_segment_id, name: c.name };
                })) ||
              [],
          });
        });
        if (response.segments.length > 0) {
          response.segments.forEach((e) => {
            segmentCompanies.push({
              uid: Math.random(),
              id: e.id,
              name: e.name,
              companies: [{ id: "all", name: "All" }],
            });
          });
        }
        if (response.allow_all_segments === 1) {
          segmentCompanies.push({
            uid: Math.random(),
            id: "all",
            name: "All",
            companies: [{ id: "all", name: "All" }],
          });
        }
        if (response.companies.length > 0) {
          segmentCompanies.push({
            uid: Math.random(),
            id: "all",
            name: "All",
            companies: response.companies.map((c) => {
              return { id: c.id, name: c.name, company_segment_id: c.id };
            }),
          });
        }

        let selectedBenchmarks = this.state.selectedBenchmarks;
        if (
          response.benchmark_mappings &&
          response.benchmark_mappings.segments
        ) {
          response.benchmark_mappings.segments.forEach((s) => {
            selectedBenchmarks.push({
              uid: Math.random(),
              benchmarkId: "All",
              benchmarkName: "All",
              segmentName: s.name,
              segmentId: s.id,
            });
          });
        }
        if (
          response.benchmark_mappings &&
          response.benchmark_mappings.benchmarks
        ) {
          response.benchmark_mappings.benchmarks.forEach((s) => {
            selectedBenchmarks.push({
              uid: Math.random(),
              benchmarkId: s.id,
              benchmarkName: s.name,
              segmentName: s.segment.name,
              segmentId: s.segment.id,
            });
          });
        }
        if (
          response.benchmark_mappings &&
          response.benchmark_mappings.allow_all
        ) {
          selectedBenchmarks.push({
            uid: Math.random(),
            benchmarkId: "All",
            benchmarkName: "All",
            segmentName: "All",
            segmentId: "All",
          });
        }

        let selectedMarketForecasts = this.state.selectedMarketForecasts;
        if (
          response.market_forecast_mappings &&
          response.market_forecast_mappings.segments
        ) {
          response.market_forecast_mappings.segments.forEach((s) => {
            selectedMarketForecasts.push({
              uid: Math.random(),
              marketForecastId: "All",
              marketForecastName: "All",
              segmentName: s.name,
              segmentId: s.id,
            });
          });
        }
        if (
          response.market_forecast_mappings &&
          response.market_forecast_mappings.market_forecasts
        ) {
          response.market_forecast_mappings.market_forecasts.forEach((s) => {
            selectedMarketForecasts.push({
              uid: Math.random(),
              marketForecastId: s.id,
              marketForecastName: s.name,
              segmentName: s.segment.name,
              segmentId: s.segment.id,
            });
          });
        }
        if (
          response.market_forecast_mappings &&
          response.market_forecast_mappings.allow_all
        ) {
          selectedMarketForecasts.push({
            uid: Math.random(),
            marketForecastId: "All",
            marketForecastName: "All",
            segmentName: "All",
            segmentId: "All",
          });
        }

        // landscape changes
        let selectedMarketLandscapes = this.state.selectedMarketLandscapes;
        if (
          response.market_landscape_mappings &&
          response.market_landscape_mappings.segments
        ) {
          response.market_landscape_mappings.segments.forEach((s) => {
            selectedMarketLandscapes.push({
              uid: Math.random(),
              marketLandscapeId: "All",
              marketLandscapeName: "All",
              segmentName: s.name,
              segmentId: s.id,
            });
          });
        }
        if (
          response.market_landscape_mappings &&
          response.market_landscape_mappings.market_landscapes
        ) {
          response.market_landscape_mappings.market_landscapes.forEach((s) => {
            selectedMarketLandscapes.push({
              uid: Math.random(),
              marketLandscapeId: s.id,
              marketLandscapeName: s.name,
              segmentName: s.segment.name,
              segmentId: s.segment.id,
            });
          });
        }
        if (
          response.market_landscape_mappings &&
          response.market_landscape_mappings.allow_all
        ) {
          selectedMarketLandscapes.push({
            uid: Math.random(),
            marketLandscapeId: "All",
            marketLandscapeName: "All",
            segmentName: "All",
            segmentId: "All",
          });
        }
        let selectedSpecialReports = this.state.selectedSpecialReports;
        if (
          response.special_report_mappings &&
          response.special_report_mappings.segments
        ) {
          response.special_report_mappings.segments.forEach((s) => {
            selectedSpecialReports.push({
              uid: Math.random(),
              specialReportId: "All",
              specialReportName: "All",
              segmentName: s.name,
              segmentId: s.id,
            });
          });
        }
        if (
          response.special_report_mappings &&
          response.special_report_mappings.special_reports
        ) {
          response.special_report_mappings.special_reports.forEach((s) => {
            selectedSpecialReports.push({
              uid: Math.random(),
              specialReportId: s.id,
              specialReportName: s.name,
              segmentName: s.segment.name,
              segmentId: s.segment.id,
            });
          });
        }
        if (
          response.special_report_mappings &&
          response.special_report_mappings.allow_all
        ) {
          selectedSpecialReports.push({
            uid: Math.random(),
            specialReportId: "All",
            specialReportName: "All",
            segmentName: "All",
            segmentId: "All",
          });
        }

        let selectedCustomerStudies = this.state.selectedCustomerStudies;
        if (
          response.customer_study_mappings &&
          response.customer_study_mappings.segments
        ) {
          response.customer_study_mappings.segments.forEach((s) => {
            selectedCustomerStudies.push({
              uid: Math.random(),
              customerStudiesId: "All",
              customerStudiesName: "All",
              segmentName: s.name,
              segmentId: s.id,
            });
          });
        }
        if (
          response.customer_study_mappings &&
          response.customer_study_mappings.customer_study
        ) {
          response.customer_study_mappings.customer_study.forEach((s) => {
            selectedCustomerStudies.push({
              uid: Math.random(),
              customerStudiesId: s.id,
              customerStudiesName: s.name,
              segmentName: s.segment.name,
              segmentId: s.segment.id,
            });
          });
        }
        if (
          response.customer_study_mappings &&
          response.customer_study_mappings.allow_all
        ) {
          selectedCustomerStudies.push({
            uid: Math.random(),
            customerStudiesId: "All",
            customerStudiesName: "All",
            segmentName: "All",
            segmentId: "All",
          });
        }

        let selectedEcosystems = this.state.selectedEcosystems;
        if (
          response.ecosystem_mappings &&
          response.ecosystem_mappings.segments
        ) {
          response.ecosystem_mappings.segments.forEach((s) => {
            selectedEcosystems.push({
              uid: Math.random(),
              ecosystemId: "All",
              ecosystemName: "All",
              segmentName: s.name,
              segmentId: s.id,
            });
          });
        }
        if (
          response.ecosystem_mappings &&
          response.ecosystem_mappings.ecosystems
        ) {
          response.ecosystem_mappings.ecosystems.forEach((s) => {
            selectedEcosystems.push({
              uid: Math.random(),
              ecosystemId: s.id,
              ecosystemName: s.name,
              segmentName: s.segment.name,
              segmentId: s.segment.id,
            });
          });
        }
        if (
          response.ecosystem_mappings &&
          response.ecosystem_mappings.allow_all
        ) {
          selectedEcosystems.push({
            uid: Math.random(),
            ecosystemId: "All",
            ecosystemName: "All",
            segmentName: "All",
            segmentId: "All",
          });
        }

        this.setState({
          clientName: response.name,
          contactPersonName: response.contact_person
            ? response.contact_person
            : response.contact_email,
          contactEmail: response.contact_email,
          contactNumber: response.contact_phone,
          website: response.website,
          address: response.address,
          startDate:
            response.start_date &&
            response.start_date !== null &&
            response.start_date !== ""
              ? new Date(response.start_date)
              : null,
          endDate:
            response.end_date &&
            response.end_date !== null &&
            response.end_date !== ""
              ? new Date(response.end_date)
              : null,
          isAllowLifetime: response.no_end_date === 1 ? true : false,
          noOfTotalUsers: response.total_users,
          isUserCountUnlimited: response.unlimited_users === 1 ? true : false,
          segmentCompanies: segmentCompanies,
          isFormSubmitted: false,
          apiError: null,
          selectedBenchmarks: selectedBenchmarks,
          selectedMarketForecasts: selectedMarketForecasts,
          selectedMarketLandscapes: selectedMarketLandscapes,
          selectedCustomerStudies: selectedCustomerStudies,
          selectedEcosystems: selectedEcosystems,
          isTBRClient: IsTBRIClient(response),
          selectedSpecialReports,
          is_set_password: response.is_set_password,
          is_sso: response.is_sso,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handleSegmentChange = (e) => {
    let segments = [...this.state.segmentList];
    this.setState({ isAllCompany: false });
    if (e.id === "all") {
      this.setState({ isAllSegment: true });
    } else {
      this.setState({ isAllSegment: false });
    }
    segments.forEach((s) => {
      s.is_selected = e.id === s.id ? true : false;
      s.companies &&
        s.companies.forEach((com) => {
          com.is_selected = false;
        });
    });
    this.setState({ segmentList: segments });
  };
  handleCompanyChange = (e) => {
    let selectedCompanies = e.map((a) => a.id);
    let segments = [...this.state.segmentList];
    let s = segments.find((s) => s.is_selected === true);
    s.companies &&
      s.companies.forEach((c) => {
        c.is_selected = selectedCompanies.includes(c.id);
      });
    this.setState({ segmentList: segments });
  };
  handleBenchmarkSegmentChange = (e) => {
    let benchmarkSegmentsList = [...this.state.benchmarkSegmentsList];
    benchmarkSegmentsList.forEach((s) => {
      s.is_selected = e.id === s.id ? true : false;
    });
    let isAllBenchmarkSegment = false;
    if (e.id === "all") {
      isAllBenchmarkSegment = true;
    } else {
      isAllBenchmarkSegment = false;
    }
    this.setState({
      benchmarkSegmentsList: benchmarkSegmentsList,
      isAllBenchmarkSegment: isAllBenchmarkSegment,
    });
  };
  handleBenchmarkChange = (e) => {
    let selectedBenchmarks = e.map((a) => a.id);
    let benchmarkList = [...this.state.benchmarkList];
    benchmarkList.forEach((c) => {
      c.is_selected = selectedBenchmarks.includes(c.id);
    });
    this.setState({
      benchmarkList: benchmarkList,
      selectedBenchMarkList: selectedBenchmarks,
    });
  };

  handleMarketForecastSegmentChange = (e) => {
    let marketForecastSegmentsList = [...this.state.marketForecastSegmentsList];
    marketForecastSegmentsList.forEach((s) => {
      s.is_selected = e.id === s.id ? true : false;
    });
    let isAllMarketForecastSegment = false;
    if (e.id === "all") {
      isAllMarketForecastSegment = true;
    } else {
      isAllMarketForecastSegment = false;
    }
    this.setState({
      marketForecastSegmentsList: marketForecastSegmentsList,
      isAllMarketForecastSegment: isAllMarketForecastSegment,
    });
  };
  handleMarketForecastChange = (e) => {
    let selectedMarketForecasts = e.map((a) => a.id);
    let marketForecastList = [...this.state.marketForecastList];
    marketForecastList.forEach((c) => {
      c.is_selected = selectedMarketForecasts.includes(c.id);
    });
    this.setState({
      marketForecastList: marketForecastList,
      selectedMarketForecastList: selectedMarketForecasts,
    });
  };

  handleInputChange = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.id]: e.target.value,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  dateOnChangeHandler = (key, val) => {
    this.setState(
      {
        ...this.state,
        [key]: val,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  // This is for handling checkbox for clients
  handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    const updatedState = { [id]: checked };

    if (id === "isUserCountUnlimited") {
      updatedState.noOfTotalUsers = checked ? 0 : this.state.noOfTotalUsers;
    } else {
      updatedState.MaximumUsers = this.state.MaximumUsers;
    }

    this.setState(updatedState, () => {
      if (this.state.isFormSubmitted === true) {
        this.isFormValid();
      }
    });
  };

  // This is for handling handleCheckboxInviteOption for clients
  handleCheckboxInviteOption = (e) => {
    const { id, checked } = e.target;
    this.setState({
      // Update state dynamically based on checkbox ID
      [id]: checked ? 1 : 0,
    });
  };

  handleAddSegmentCompany = () => {
    const {
      segmentList,
      isAllCompany,
      isAllSegment,
      segmentCompanies,
      isFormSubmitted,
    } = this.state;

    let selectedSegments = segmentList.find((s) => s.is_selected);
    let selectedCompanies = [];

    if (isAllCompany) {
      selectedCompanies = [
        {
          id: "all",
          is_selected: true,
          name: "All",
          company_segment_id: "all",
        },
      ];
    } else {
      selectedCompanies = selectedSegments
        ? selectedSegments.companies.filter((c) => c.is_selected)
        : [];
    }

    let finalObject = isAllCompany && isAllSegment ? [] : [...segmentCompanies];

    if (selectedCompanies.length > 0) {
      let segmentIndex = finalObject.findIndex(
        (f) => f.id === selectedSegments.id
      );
      if (segmentIndex < 0) {
        finalObject.push({
          uid: Math.random(),
          id: selectedSegments.id,
          name: selectedSegments.name,
          companies: selectedCompanies.map((c) => ({
            id: c.company_segment_id,
            name: c.name,
          })),
        });
      } else {
        let existing = [...finalObject[segmentIndex].companies];
        selectedCompanies.forEach((c) => {
          if (existing.findIndex((f) => f.id === c.company_segment_id) < 0) {
            if (
              c.company_segment_id !== "all" ||
              (c.company_segment_id === "all" &&
                selectedCompanies.length === 1 &&
                existing.length === 0)
            ) {
              if (typeof existing.find((e) => e.id === "all") === "undefined") {
                existing.push({ id: c.company_segment_id, name: c.name });
              }
            }
          }
        });

        finalObject[segmentIndex].companies =
          typeof selectedCompanies.find((e) => e.id === "all") !== "undefined"
            ? [
                {
                  id: "all",
                  is_selected: true,
                  name: "All",
                  company_segment_id: "all",
                },
              ]
            : existing;
      }
    }

    if (finalObject !== this.state.segmentCompanies) {
      this.setState(
        {
          segmentCompanies: finalObject,
          unselectedsegmentList: segmentList.filter((s) => !s.is_selected),
        },
        () => {
          if (isFormSubmitted) this.isFormValid();
        }
      );
    }
  };

  handleAddBenchmarks = () => {
    let selectedBenchmarks = [...this.state.selectedBenchmarks];
    let benchmarkList = [...this.state.benchmarkList];
    let benchmarkSegmentsList = [...this.state.benchmarkSegmentsList];
    let isValid = false;
    if (
      this.state.isAllBenchmarkSegment &&
      (this.state.isAllBenchmark ||
        this.state.benchmarkList.filter((c) => c.is_selected === true)
          .length === 0)
    ) {
      selectedBenchmarks = [];
      selectedBenchmarks.push({
        uid: Math.random(),
        benchmarkId: "All",
        benchmarkName: "All",
        segmentName: "All",
        segmentId: "All",
      });
      isValid = true;
    } else {
      let selectedSegment = benchmarkSegmentsList.find(
        (s) => s.is_selected === true
      );
      if (selectedSegment && this.state.isAllBenchmark) {
        if (
          selectedBenchmarks.find(
            (sb) => sb.benchmarkId === "All" && sb.segmentId === "All"
          )
        ) {
        } else {
          selectedBenchmarks = selectedBenchmarks.filter(
            (s) => s.segmentId !== selectedSegment.id
          );
          selectedBenchmarks.push({
            uid: Math.random(),
            benchmarkId: "All",
            benchmarkName: "All",
            segmentName: selectedSegment.name,
            segmentId: selectedSegment.id,
          });
          isValid = true;
        }
      } else if (
        !this.state.isAllBenchmark &&
        this.state.benchmarkList.filter((c) => c.is_selected === true).length >
          0
      ) {
        if (
          selectedBenchmarks.find(
            (sb) => sb.benchmarkId === "All" && sb.segmentId === "All"
          )
        ) {
        } else {
          this.state.benchmarkList
            .filter((c) => c.is_selected === true)
            .forEach((b) => {
              if (
                !selectedBenchmarks.find((s) => s.benchmarkId === b.id) &&
                !selectedBenchmarks.find(
                  (s) => s.segmentId === b.segment.id && s.benchmarkId === "All"
                )
              ) {
                selectedBenchmarks.push({
                  uid: Math.random(),
                  benchmarkId: b.id,
                  benchmarkName: b.name,
                  segmentName: b.segment.name,
                  segmentId: b.segment.id,
                });
                isValid = true;
              }
            });
        }
      }
    }
    if (isValid) {
      benchmarkList = benchmarkList.filter(
        (s) => !this.state.selectedBenchMarkList.includes(s.id)
      );
      benchmarkSegmentsList.forEach((b) => (b.is_selected = false));
      this.setState({
        selectedBenchmarks: selectedBenchmarks,
        isAllBenchmarkSegment: false,
        isAllBenchmark: false,
        benchmarkList: benchmarkList,
        benchmarkSegmentsList: benchmarkSegmentsList,
      });
    }
  };

  handleAddMarketForecasts = () => {
    let selectedMarketForecasts = [...this.state.selectedMarketForecasts];
    let marketForecastList = [...this.state.marketForecastList];
    let marketForecastSegmentsList = [...this.state.marketForecastSegmentsList];
    let isValid = false;
    if (
      this.state.isAllMarketForecastSegment &&
      (this.state.isAllMarketForecast ||
        this.state.marketForecastList.filter((c) => c.is_selected === true)
          .length === 0)
    ) {
      selectedMarketForecasts = [];
      selectedMarketForecasts.push({
        uid: Math.random(),
        marketForecastId: "All",
        marketForecastName: "All",
        segmentName: "All",
        segmentId: "All",
      });
      isValid = true;
    } else {
      let selectedSegment = marketForecastSegmentsList.find(
        (s) => s.is_selected === true
      );
      if (selectedSegment && this.state.isAllMarketForecast) {
        if (
          selectedMarketForecasts.find(
            (sb) => sb.marketForecastId === "All" && sb.segmentId === "All"
          )
        ) {
        } else {
          selectedMarketForecasts = selectedMarketForecasts.filter(
            (s) => s.segmentId !== selectedSegment.id
          );
          selectedMarketForecasts.push({
            uid: Math.random(),
            marketForecastId: "All",
            marketForecastName: "All",
            segmentName: selectedSegment.name,
            segmentId: selectedSegment.id,
          });
          isValid = true;
        }
      } else if (
        !this.state.isAllMarketForecast &&
        this.state.marketForecastList.filter((c) => c.is_selected === true)
          .length > 0
      ) {
        if (
          selectedMarketForecasts.find(
            (sb) => sb.marketForecastId === "All" && sb.segmentId === "All"
          )
        ) {
        } else {
          this.state.marketForecastList
            .filter((c) => c.is_selected === true)
            .forEach((b) => {
              if (
                !selectedMarketForecasts.find(
                  (s) => s.marketForecastId === b.id
                ) &&
                !selectedMarketForecasts.find(
                  (s) =>
                    s.segmentId === b.segment.id && s.marketForecastId === "All"
                )
              ) {
                selectedMarketForecasts.push({
                  uid: Math.random(),
                  marketForecastId: b.id,
                  marketForecastName: b.name,
                  segmentName: b.segment.name,
                  segmentId: b.segment.id,
                });
                isValid = true;
              }
            });
        }
      }
    }
    if (isValid) {
      marketForecastList = marketForecastList.filter(
        (b) => !this.state.selectedMarketForecastList.includes(b.id)
      );
      marketForecastSegmentsList.forEach((b) => (b.is_selected = false));
      this.setState({
        selectedMarketForecasts: selectedMarketForecasts,
        isAllMarketForecastSegment: false,
        isAllMarketForecast: false,
        marketForecastList: marketForecastList,
        marketForecastSegmentsList: marketForecastSegmentsList,
      });
    }
  };
  handleAddSpecialReports = () => {
    let selectedSpecialReports = [...this.state.selectedSpecialReports];
    let specialReportsList = [...this.state.specialReportsList];
    let specialReportsSegmentsList = [...this.state.specialReportsSegmentsList];
    let isValid = false;

    if (
      this.state.isAllSpecialReportsSegment &&
      (this.state.isAllSpecialReports ||
        this.state.specialReportsList.filter((c) => c.is_selected === true)
          .length === 0)
    ) {
      selectedSpecialReports = [];
      selectedSpecialReports.push({
        uid: Math.random(),
        specialReportId: "All",
        specialReportName: "All",
        segmentName: "All",
        segmentId: "All",
      });
      isValid = true;
    } else {
      let selectedSegment = specialReportsSegmentsList.find(
        (s) => s.is_selected === true
      );
      if (selectedSegment && this.state.isAllSpecialReports) {
        const selectedSpecialReportsArray = selectedSpecialReports.find(
          (sb) => sb.specialReportId === "All" && sb.segmentId === "All"
        );

        if (selectedSpecialReportsArray) {
        } else {
          selectedSpecialReports = selectedSpecialReports.filter(
            (s) => s.segmentId !== selectedSegment.id
          );
          selectedSpecialReports.push({
            uid: Math.random(),
            specialReportId: "All",
            specialReportName: "All",
            segmentName: selectedSegment.name,
            segmentId: selectedSegment.id,
          });
          isValid = true;
        }
      } else if (
        !this.state.isAllSpecialReports &&
        this.state.specialReportsList.filter((c) => c.is_selected === true)
          .length > 0
      ) {
        if (
          selectedSpecialReports.find(
            (sb) => sb.specialReportId === "All" && sb.segmentId === "All"
          )
        ) {
        } else {
          this.state.specialReportsList
            .filter((c) => c.is_selected === true)
            .forEach((b) => {
              if (
                !selectedSpecialReports.find(
                  (s) => s.specialReportId === b.id
                ) &&
                !selectedSpecialReports.find(
                  (s) =>
                    s.segmentId === b.segment.id && s.specialReportId === "All"
                )
              ) {
                selectedSpecialReports.push({
                  uid: Math.random(),
                  specialReportId: b.id,
                  specialReportName: b.name,
                  segmentName: b.segment.name,
                  segmentId: b.segment.id,
                });
                isValid = true;
              }
            });
        }
      }
    }
    if (isValid) {
      specialReportsList = specialReportsList.filter(
        (b) => !this.state.selectedSpecialReportsList.includes(b.id)
      );

      specialReportsSegmentsList = specialReportsSegmentsList.map((b) => {
        b.is_selected = false;
        return b;
      });

      this.setState({
        selectedSpecialReports,
        isAllSpecialReportsSegment: false,
        isAllSpecialReports: false,
        specialReportsList,
        specialReportsSegmentsList,
      });
    }
  };

  handleAddCustomerStudies = () => {
    let selectedCustomerStudies = [...this.state.selectedCustomerStudies];
    let customerStudiesList = [...this.state.customerStudiesList];
    let customerStudiesSegmentsList = [
      ...this.state.customerStudiesSegmentsList,
    ];
    let isValid = false;
    if (
      this.state.isAllCustomerStudiesSegment &&
      (this.state.isAllCustomerStudies ||
        this.state.customerStudiesList.filter((c) => c.is_selected === true)
          .length === 0)
    ) {
      selectedCustomerStudies = [];
      selectedCustomerStudies.push({
        uid: Math.random(),
        customerStudiesId: "All",
        customerStudiesName: "All",
        segmentName: "All",
        segmentId: "All",
      });
      isValid = true;
    } else {
      let selectedSegment = customerStudiesSegmentsList.find(
        (s) => s.is_selected === true
      );
      if (selectedSegment && this.state.isAllCustomerStudies) {
        if (
          selectedCustomerStudies.find(
            (sb) => sb.customerStudiesId === "All" && sb.segmentId === "All"
          )
        ) {
        } else {
          selectedCustomerStudies = selectedCustomerStudies.filter(
            (s) => s.segmentId !== selectedSegment.id
          );
          selectedCustomerStudies.push({
            uid: Math.random(),
            customerStudiesId: "All",
            customerStudiesName: "All",
            segmentName: selectedSegment.name,
            segmentId: selectedSegment.id,
          });
          isValid = true;
        }
      } else if (
        !this.state.isAllCustomerStudies &&
        this.state.customerStudiesList.filter((c) => c.is_selected === true)
          .length > 0
      ) {
        if (
          selectedCustomerStudies.find(
            (sb) => sb.customerStudiesId === "All" && sb.segmentId === "All"
          )
        ) {
        } else {
          this.state.customerStudiesList
            .filter((c) => c.is_selected === true)
            .forEach((b) => {
              if (
                !selectedCustomerStudies.find(
                  (s) => s.customerStudiesId === b.id
                ) &&
                !selectedCustomerStudies.find(
                  (s) =>
                    s.segmentId === b.segment.id &&
                    s.customerStudiesId === "All"
                )
              ) {
                selectedCustomerStudies.push({
                  uid: Math.random(),
                  customerStudiesId: b.id,
                  customerStudiesName: b.name,
                  segmentName: b.segment.name,
                  segmentId: b.segment.id,
                });
                isValid = true;
              }
            });
        }
      }
    }
    if (isValid) {
      customerStudiesList = customerStudiesList.filter(
        (b) => !this.state.selectedCustomerStudiesList.includes(b.id)
      );
      customerStudiesSegmentsList.forEach((b) => (b.is_selected = false));
      this.setState({
        selectedCustomerStudies,
        isAllCustomerStudiesSegment: false,
        isAllCustomerStudies: false,
        customerStudiesList,
        customerStudiesSegmentsList,
      });
    }
  };

  handleAddEcosystem = () => {
    let selectedEcosystems = [...this.state.selectedEcosystems];
    let ecosystemList = [...this.state.ecosystemList];
    let ecosystemSegmentsList = [...this.state.ecosystemSegmentsList];
    let isValid = false;
    if (
      this.state.isAllEcosystemSegment &&
      (this.state.isAllEcosystem ||
        this.state.ecosystemList.filter((c) => c.is_selected === true)
          .length === 0)
    ) {
      selectedEcosystems = [];
      selectedEcosystems.push({
        uid: Math.random(),
        ecosystemId: "All",
        ecosystemName: "All",
        segmentName: "All",
        segmentId: "All",
      });
      isValid = true;
    } else {
      let selectedSegment = ecosystemSegmentsList.find(
        (s) => s.is_selected === true
      );
      if (selectedSegment && this.state.isAllEcosystem) {
        if (
          selectedEcosystems.find(
            (sb) => sb.ecosystemId === "All" && sb.segmentId === "All"
          )
        ) {
        } else {
          selectedEcosystems = selectedEcosystems.filter(
            (s) => s.segmentId !== selectedSegment.id
          );
          selectedEcosystems.push({
            uid: Math.random(),
            ecosystemId: "All",
            ecosystemName: "All",
            segmentName: selectedSegment.name,
            segmentId: selectedSegment.id,
          });
          isValid = true;
        }
      } else if (
        !this.state.isAllEcosystem &&
        this.state.ecosystemList.filter((c) => c.is_selected === true).length >
          0
      ) {
        if (
          selectedEcosystems.find(
            (sb) => sb.ecosystemId === "All" && sb.segmentId === "All"
          )
        ) {
        } else {
          this.state.ecosystemList
            .filter((c) => c.is_selected === true)
            .forEach((b) => {
              if (
                !selectedEcosystems.find((s) => s.ecosystemId === b.id) &&
                !selectedEcosystems.find(
                  (s) => s.segmentId === b.segment.id && s.ecosystemId === "All"
                )
              ) {
                selectedEcosystems.push({
                  uid: Math.random(),
                  ecosystemId: b.id,
                  ecosystemName: b.name,
                  segmentName: b.segment.name,
                  segmentId: b.segment.id,
                });
                isValid = true;
              }
            });
        }
      }
    }
    if (isValid) {
      ecosystemList = ecosystemList.filter(
        (b) => !this.state.selectedEcosystemList.includes(b.id)
      );
      ecosystemSegmentsList = ecosystemSegmentsList.map((b) => {
        b.is_selected = false;
        return b;
      });
      this.setState({
        selectedEcosystems,
        isAllEcosystemSegment: false,
        isAllEcosystem: false,
        ecosystemList,
        ecosystemSegmentsList,
      });
    }
  };

  removeSegmentConfirmation = (uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove Segment</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.CLIENT.REMOVE_SEGMENT_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_segment", id: uid },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeBenchmarkSegmentConfirmation = (type, uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {type === "segment" ? "Remove Benchmark Segment" : "Remove Benchmark"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {type === "segment"
              ? constants.CLIENT.REMOVE_BENCHMARK_SEGMENT_CONFIRM_MESSAGE
              : constants.CLIENT.REMOVE_BENCHMARK_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type:
          type === "segment" ? "remove_benchmark_segment" : "remove_benchmark",
        id: uid,
      },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeMarketForecastSegmentConfirmation = (type, uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {type === "segment"
            ? "Remove Marketforecast Segment"
            : "Remove Marketforecast"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {type === "segment"
              ? constants.CLIENT.REMOVE_MARKET_FORECAST_SEGMENT_CONFIRM_MESSAGE
              : constants.CLIENT.REMOVE_MARKET_FORECAST_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type:
          type === "segment"
            ? "remove_marketforecast_segment"
            : "remove_marketforecast",
        id: uid,
      },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeSegment = (uid) => {
    let finalObject = [...this.state.segmentCompanies];
    finalObject = finalObject.filter((ob) => ob.uid !== uid);
    var segment_status = this.state.isAllSegment;
    var company_status = this.state.isAllCompany;
    if (!finalObject.length) {
      segment_status = false;
      company_status = false;
    }
    let segmentId = this.state.segmentCompanies.find((s) => s.uid === uid);
    this.setState(
      {
        ...this.state,
        segmentList: this.state.segmentList.map((s) => {
          if (s.id === segmentId.id) {
            s.is_selected = false;
          }
          return s;
        }),
        segmentCompanies: finalObject,
        isAllSegment: segment_status,
        isAllCompany: company_status,
        unselectedsegmentList: this.state.segmentList.filter(
          (s) => s.is_selected === false
        ),
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  removeBenchmarkSegment = (sid) => {
    let finalObject = [...this.state.selectedBenchmarks];

    // Filter out benchmarks matching the segment ID being removed
    let finalObjectfiltered = finalObject.filter((ob) => ob.segmentId === sid);

    // Get IDs of benchmarks being removed
    let ids = finalObjectfiltered.map((s) => s.benchmarkId);

    // Find benchmarks in unselected list that match the removed IDs
    let additionalBenchMark = this.state.unselectedBenchMarkList
      .filter((s) => ids.includes(s.id))
      .map((s) => {
        // Avoid mutating the original object
        return { ...s, is_selected: false };
      });

    // Remove benchmarks with the specified segment ID from selectedBenchmarks
    finalObject = finalObject.filter((ob) => ob.segmentId !== sid);

    // Add unique benchmarks to benchmarkList
    const updatedBenchmarkList = [
      ...this.state.benchmarkList.filter(
        (existing) =>
          !additionalBenchMark.find((added) => added.id === existing.id)
      ),
      ...additionalBenchMark,
    ];

    this.setState(
      {
        ...this.state,
        benchmarkList: updatedBenchmarkList,
        selectedBenchmarks: finalObject,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  removeBenchmark = (uid) => {
    // Find the benchmark to be removed from unselectedBenchMarkList
    let tempBenchMark = this.state.unselectedBenchMarkList
      .filter((s) => s.id === uid)
      .map((s) => {
        // Avoid mutating the original object
        return { ...s, is_selected: false };
      });

    // Remove the benchmark from the selectedBenchmarks list
    let finalObject = this.state.selectedBenchmarks.filter(
      (ob) => ob.benchmarkId !== uid
    );

    // Add unique benchmarks to benchmarkList
    const updatedBenchmarkList = [
      ...this.state.benchmarkList.filter(
        (existing) => !tempBenchMark.find((added) => added.id === existing.id)
      ),
      ...tempBenchMark,
    ];

    this.setState(
      {
        ...this.state,
        benchmarkList: updatedBenchmarkList,
        selectedBenchmarks: finalObject,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  removeMarketForecastSegment = (sid) => {
    // Create a copy of the selected market forecasts
    let finalObject = [...this.state.selectedMarketForecasts];

    // Find the forecasts belonging to the given segment ID
    let finalObjectfiltered = finalObject.filter((ob) => ob.segmentId === sid);

    // Extract the IDs of the forecasts to be removed
    let ids = finalObjectfiltered.map((s) => s.marketForecastId);

    // Find corresponding forecasts in the unselected list and update `is_selected`
    let additionalMarketForecast = this.state.unselectedmarketforecastList
      .filter((s) => ids.includes(s.id))
      .map((s) => {
        return { ...s, is_selected: false };
      });

    // Remove the forecasts for the given segment from the selected list
    finalObject = finalObject.filter((ob) => ob.segmentId !== sid);

    // Update marketForecastList with unique entries only
    const updatedMarketForecastList = [
      ...this.state.marketForecastList.filter(
        (existing) =>
          !additionalMarketForecast.find((added) => added.id === existing.id)
      ),
      ...additionalMarketForecast,
    ];

    // Update the state
    this.setState(
      {
        ...this.state,
        marketForecastList: updatedMarketForecastList,
        selectedMarketForecasts: finalObject,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  removeMarketForecast = (uid) => {
    // Find the forecast to be removed from the unselected list and update `is_selected`
    let tempForeCast = this.state.unselectedmarketforecastList
      .filter((s) => s.id === uid)
      .map((s) => {
        return { ...s, is_selected: false };
      });

    // Remove the forecast with the given ID from the selected list
    let finalObject = this.state.selectedMarketForecasts.filter(
      (ob) => ob.marketForecastId !== uid
    );

    // Update marketForecastList with unique entries only
    const updatedMarketForecastList = [
      ...this.state.marketForecastList.filter(
        (existing) => !tempForeCast.find((added) => added.id === existing.id)
      ),
      ...tempForeCast,
    ];

    // Update the state
    this.setState(
      {
        ...this.state,
        marketForecastList: updatedMarketForecastList,
        selectedMarketForecasts: finalObject,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  removeCompanyConfirmation = (uid, companyId) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove Company</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.COMPANY.REMOVE_COMPANY_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type: "remove_company",
        id: uid,
        companyId: companyId,
      },
      dailogModalStyleType: "danger-modal",
    });
  };
  removeSpecialReportSegment = (sid) => {
    let finalObject = [...this.state.selectedSpecialReports];

    // Find reports related to the segment ID
    let finalObjectfiltered = finalObject.filter((ob) => ob.segmentId === sid);

    // Extract IDs to be removed
    let ids = finalObjectfiltered.map((s) => s.specialReportId);

    // Find corresponding reports in the unselected list and update `is_selected`
    let additionalSpecialReports = this.state.unselectedSpecialReportsList
      .filter((s) => ids.includes(s.id))
      .map((s) => {
        return { ...s, is_selected: false };
      });

    // Remove reports for the given segment from the selected list
    finalObject = finalObject.filter((ob) => ob.segmentId !== sid);

    // Ensure unique entries in `specialReportsList`
    const updatedSpecialReportsList = [
      ...this.state.specialReportsList.filter(
        (existing) =>
          !additionalSpecialReports.find((added) => added.id === existing.id)
      ),
      ...additionalSpecialReports,
    ];

    // Update the state
    this.setState(
      {
        ...this.state,
        specialReportsList: updatedSpecialReportsList,
        selectedSpecialReports: finalObject,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  removeCustomerStudiesSegment = (sid) => {
    let finalObject = [...this.state.selectedCustomerStudies];

    // Find studies related to the segment ID
    let finalObjectfiltered = finalObject.filter((ob) => ob.segmentId === sid);

    // Extract IDs to be removed
    let ids = finalObjectfiltered.map((s) => s.customerStudiesId);

    // Find corresponding studies in the unselected list and update `is_selected`
    let additionalCustomerStudies = this.state.unselectedCustomerStudiesList
      .filter((s) => ids.includes(s.id))
      .map((s) => {
        return { ...s, is_selected: false };
      });

    // Remove studies for the given segment from the selected list
    finalObject = finalObject.filter((ob) => ob.segmentId !== sid);

    // Ensure unique entries in `customerStudiesList`
    const updatedCustomerStudiesList = [
      ...this.state.customerStudiesList.filter(
        (existing) =>
          !additionalCustomerStudies.find((added) => added.id === existing.id)
      ),
      ...additionalCustomerStudies,
    ];

    // Update the state
    this.setState(
      {
        ...this.state,
        customerStudiesList: updatedCustomerStudiesList,
        selectedCustomerStudies: finalObject,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  removeEcosystemSegment = (sid) => {
    let finalObject = [...this.state.selectedEcosystems];

    // Find ecosystems related to the segment ID
    let finalObjectfiltered = finalObject.filter((ob) => ob.segmentId === sid);

    // Extract IDs to be removed
    let ids = finalObjectfiltered.map((s) => s.ecosystemId);

    // Find corresponding ecosystems in the unselected list and update `is_selected`
    let additionalEcosystem = this.state.unselectedEcosystemList
      .filter((s) => ids.includes(s.id))
      .map((s) => {
        return { ...s, is_selected: false };
      });

    // Remove ecosystems for the given segment from the selected list
    finalObject = finalObject.filter((ob) => ob.segmentId !== sid);

    // Ensure unique entries in `ecosystemList`
    const updatedEcosystemList = [
      ...this.state.ecosystemList.filter(
        (existing) =>
          !additionalEcosystem.find((added) => added.id === existing.id)
      ),
      ...additionalEcosystem,
    ];

    // Update the state
    this.setState(
      {
        ...this.state,
        ecosystemList: updatedEcosystemList,
        selectedEcosystems: finalObject,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  removeCompany = (uid, companyId) => {
    let finalObject = [...this.state.segmentCompanies];
    let index = finalObject.findIndex((f) => f.uid === uid);
    let m = finalObject[index].companies.filter((ob) => ob.id !== companyId);
    finalObject[index].companies = m;
    this.setState(
      {
        ...this.state,
        segmentCompanies: finalObject,
      },
      () => {
        if (m.length === 0) this.removeSegment(uid);
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  findPos = (element) => {
    if (element) {
      const Yaxis = element.offsetTop + 75;
      window.scrollTo(0, Yaxis);
    }
  };
  isFormValid = () => {
    let errorsList = [];
    let formError = false;
    let clientNameError = false;
    let contactPersonNameError = false;
    let contactEmailError = false;
    let contactNumberError = false;
    let startDateError = false;
    let endDateError = false;
    let noOfTotalUsersError = false;
    let userInviteOptionError = false;
    if (isEmpty(this.state.clientName.trim())) {
      clientNameError = true;
      formError = true;
      errorsList.push("clientName");
    }
    if (isEmpty(this.state.contactPersonName.trim())) {
      contactPersonNameError = true;
      formError = true;
      errorsList.push("contactPersonName");
    }
    if (isEmpty(this.state.contactEmail.trim())) {
      contactEmailError = true;
      formError = true;
      errorsList.push("contactEmail");
    } else {
      var re = constants.REG_EX.EMAIL_REG_EX;
      contactEmailError = !re.test(
        String(this.state.contactEmail).toLowerCase()
      );
      if (contactEmailError === true) {
        formError = true;
        errorsList.push("contactEmail");
      }
    }
    if (this.state.startDate === null) {
      startDateError = true;
      formError = true;
      errorsList.push("startDate");
    }
    if (this.state.isAllowLifetime === false && this.state.endDate === null) {
      endDateError = true;
      formError = true;
      errorsList.push("endDate");
    }
    if (
      this.state.startDate !== null &&
      this.state.endDate !== null &&
      !this.state.isAllowLifetime
    ) {
      if (
        !(
          new Date(this.state.startDate).getTime() <
          new Date(this.state.endDate).getTime()
        )
      ) {
        startDateError = true;
        endDateError = true;
        formError = true;
      }
    }
    if (
      this.state.isUserCountUnlimited === false &&
      (this.state.noOfTotalUsers === 0 ||
        isNaN(this.state.noOfTotalUsers) ||
        this.state.noOfTotalUsers <= 0)
    ) {
      noOfTotalUsersError = true;
      formError = true;
      errorsList.push("noOfTotalUsers");
    }
    if (this.state.isUserCountUnlimited === true) noOfTotalUsersError = null;
    if (this.state.contactNumber !== "") {
      var re_phone = constants.REG_EX.PHONE_REG_EX;
      contactNumberError = !re_phone.test(this.state.contactNumber);
      if (contactNumberError === true) {
        formError = true;
        errorsList.push("contactNumber");
      }
    }
    if (!this.state.is_set_password && !this.state.is_sso) {
      userInviteOptionError = true;
      formError = true;
    }

    scrollIntoErrors(errorsList);
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        clientNameError: clientNameError,
        contactPersonNameError: contactPersonNameError,
        contactEmailError: contactEmailError,
        contactNumberError: contactNumberError,
        startDateError: startDateError,
        endDateError: endDateError,
        noOfTotalUsersError: noOfTotalUsersError,
        userInviteOptionError: userInviteOptionError,
      },
      apiError: null,
    });
    return formError;
  };
  handleSubmit = () => {
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
          let companies = [];
          let company_segments = [];
          let segments = [];
          let isAllSegmentCheck = 0;
          let isAllBenchmarks = 0;
          let benchmarks = [];
          let benchmarkSegments = [];
          let isAllMarketForecasts = 0;
          let marketForecasts = [];
          let marketForecastSegments = [];
          let numberOfReportsPurchased = 0;
          // landscape changes
          let isAllMarketLandscapes = 0;
          let marketLandscapes = [];
          let marketLandscapeSegments = [];

          let isAllSpecialReports = 0;
          let specialReports = [];
          let specialReportsSegments = [];

          let isAllCustomerStudies = 0;
          let customerStudies = [];
          let customerStudiesSegments = [];

          let isAllEcosystems = 0;
          let ecosystems = [];
          let ecosystemSegments = [];

          if (this.state.selectedBenchmarks.length > 0) {
            if (
              this.state.selectedBenchmarks.some(
                (s) => s.benchmarkId === "All" && s.segmentId === "All"
              )
            ) {
              isAllBenchmarks = 1;
              numberOfReportsPurchased += this.state.benchmarkList.length;
            }
            if (
              this.state.selectedBenchmarks.some(
                (s) => s.segmentId !== "All" && s.benchmarkId === "All"
              )
            ) {
              this.state.selectedBenchmarks
                .filter((s) => s.segmentId !== "All" && s.benchmarkId === "All")
                .forEach((b) => {
                  benchmarkSegments.push(b.segmentId);
                  numberOfReportsPurchased += this.getReportsPurchased(
                    this.state.benchmarkList,
                    b.segmentId
                  );
                });
            }
            if (
              this.state.selectedBenchmarks.some(
                (s) => s.segmentId !== "All" && s.benchmarkId !== "All"
              )
            ) {
              this.state.selectedBenchmarks
                .filter((s) => s.segmentId !== "All" && s.benchmarkId !== "All")
                .forEach((b) => {
                  benchmarks.push(b.benchmarkId);
                  numberOfReportsPurchased++;
                });
            }
          }

          if (this.state.selectedMarketForecasts.length > 0) {
            if (
              this.state.selectedMarketForecasts.some(
                (s) => s.marketForecastId === "All" && s.segmentId === "All"
              )
            ) {
              isAllMarketForecasts = 1;
              numberOfReportsPurchased += this.state.marketForecastList.length;
            }
            if (
              this.state.selectedMarketForecasts.some(
                (s) => s.segmentId !== "All" && s.marketForecastId === "All"
              )
            ) {
              this.state.selectedMarketForecasts
                .filter(
                  (s) => s.segmentId !== "All" && s.marketForecastId === "All"
                )
                .forEach((b) => {
                  marketForecastSegments.push(b.segmentId);
                  numberOfReportsPurchased += this.getReportsPurchased(
                    this.state.marketForecastList,
                    b.segmentId
                  );
                });
            }
            if (
              this.state.selectedMarketForecasts.some(
                (s) => s.segmentId !== "All" && s.marketForecastId !== "All"
              )
            ) {
              this.state.selectedMarketForecasts
                .filter(
                  (s) => s.segmentId !== "All" && s.marketForecastId !== "All"
                )
                .forEach((b) => {
                  marketForecasts.push(b.marketForecastId);
                  numberOfReportsPurchased++;
                });
            }
          }

          if (this.state.selectedMarketLandscapes.length > 0) {
            if (
              this.state.selectedMarketLandscapes.some(
                (s) => s.marketLandscapeId === "All" && s.segmentId === "All"
              )
            ) {
              isAllMarketLandscapes = 1;
              numberOfReportsPurchased += this.state.marketLandscapeList.length;
            }
            if (
              this.state.selectedMarketLandscapes.some(
                (s) => s.segmentId !== "All" && s.marketLandscapeId === "All"
              )
            ) {
              this.state.selectedMarketLandscapes
                .filter(
                  (s) => s.segmentId !== "All" && s.marketLandscapeId === "All"
                )
                .forEach((b) => {
                  marketLandscapeSegments.push(b.segmentId);
                  numberOfReportsPurchased += this.getReportsPurchased(
                    this.state.marketLandscapeList,
                    b.segmentId
                  );
                });
            }
            if (
              this.state.selectedMarketLandscapes.some(
                (s) => s.segmentId !== "All" && s.marketLandscapeId !== "All"
              )
            ) {
              this.state.selectedMarketLandscapes
                .filter(
                  (s) => s.segmentId !== "All" && s.marketLandscapeId !== "All"
                )
                .forEach((b) => {
                  marketLandscapes.push(b.marketLandscapeId);
                  numberOfReportsPurchased++;
                });
            }
          }

          if (this.state.selectedSpecialReports.length > 0) {
            if (
              this.state.selectedSpecialReports.some(
                (s) => s.specialReportId === "All" && s.segmentId === "All"
              )
            ) {
              isAllSpecialReports = 1;
              numberOfReportsPurchased += this.state.specialReportsList.length;
            }
            if (
              this.state.selectedSpecialReports.some(
                (s) => s.segmentId !== "All" && s.specialReportId === "All"
              )
            ) {
              this.state.selectedSpecialReports
                .filter(
                  (s) => s.segmentId !== "All" && s.specialReportId === "All"
                )
                .forEach((b) => {
                  specialReportsSegments.push(b.segmentId);
                  numberOfReportsPurchased += this.getReportsPurchased(
                    this.state.specialReportsList,
                    b.segmentId
                  );
                });
            }
            if (
              this.state.selectedSpecialReports.some(
                (s) => s.segmentId !== "All" && s.specialReportId !== "All"
              )
            ) {
              this.state.selectedSpecialReports
                .filter(
                  (s) => s.segmentId !== "All" && s.specialReportId !== "All"
                )
                .forEach((b) => {
                  specialReports.push(b.specialReportId);
                  numberOfReportsPurchased++;
                });
            }
          }

          if (this.state.selectedCustomerStudies.length > 0) {
            if (
              this.state.selectedCustomerStudies.some(
                (s) => s.customerStudiesId === "All" && s.segmentId === "All"
              )
            ) {
              isAllCustomerStudies = 1;
              numberOfReportsPurchased += this.state.customerStudiesList.length;
            }
            if (
              this.state.selectedCustomerStudies.some(
                (s) => s.segmentId !== "All" && s.customerStudiesId === "All"
              )
            ) {
              this.state.selectedCustomerStudies
                .filter(
                  (s) => s.segmentId !== "All" && s.customerStudiesId === "All"
                )
                .forEach((b) => {
                  customerStudiesSegments.push(b.segmentId);
                  numberOfReportsPurchased += this.getReportsPurchased(
                    this.state.customerStudiesList,
                    b.segmentId
                  );
                });
            }
            if (
              this.state.selectedCustomerStudies.some(
                (s) => s.segmentId !== "All" && s.customerStudiesId !== "All"
              )
            ) {
              this.state.selectedCustomerStudies
                .filter(
                  (s) => s.segmentId !== "All" && s.customerStudiesId !== "All"
                )
                .forEach((b) => {
                  customerStudies.push(b.customerStudiesId);
                  numberOfReportsPurchased++;
                });
            }
          }

          if (this.state.selectedEcosystems.length > 0) {
            if (
              this.state.selectedEcosystems.some(
                (s) => s.ecosystemId === "All" && s.segmentId === "All"
              )
            ) {
              isAllEcosystems = 1;
              numberOfReportsPurchased += this.state.ecosystemList.length;
            }
            if (
              this.state.selectedEcosystems.some(
                (s) => s.segmentId !== "All" && s.ecosystemId === "All"
              )
            ) {
              this.state.selectedEcosystems
                .filter((s) => s.segmentId !== "All" && s.ecosystemId === "All")
                .forEach((b) => {
                  ecosystemSegments.push(b.segmentId);
                  numberOfReportsPurchased += this.getReportsPurchased(
                    this.state.ecosystemList,
                    b.segmentId
                  );
                });
            }
            if (
              this.state.selectedEcosystems.some(
                (s) => s.segmentId !== "All" && s.ecosystemId !== "All"
              )
            ) {
              this.state.selectedEcosystems
                .filter((s) => s.segmentId !== "All" && s.ecosystemId !== "All")
                .forEach((b) => {
                  ecosystems.push(b.ecosystemId);
                  numberOfReportsPurchased++;
                });
            }
          }

          this.state.segmentCompanies.forEach((s) => {
            s.companies.forEach((c) => {
              if (c.id !== "all" && c.id !== "-" && s.id !== "all") {
                company_segments.push(c.id);
                numberOfReportsPurchased++;
              }
              if (c.id === "all" && s.id === "all") {
                isAllSegmentCheck = 1;
                numberOfReportsPurchased +=
                  this.getCompanySegmentReportsPurchased(s.id);
              }
              if (c.id === "all" && s.id !== "all") {
                segments.push(s.id);
                numberOfReportsPurchased +=
                  this.getCompanySegmentReportsPurchased(s.id);
              }
              if (c.id !== "all" && s.id === "all") {
                companies.push(c.id);
                numberOfReportsPurchased++;
              }
            });
          });
          let clientId = this.props.match.params.id
            ? this.props.match.params.id
            : null;
          let prepareObject = {
            name: this.state.clientName,
            unlimited_users: this.state.isUserCountUnlimited === true ? 1 : 0,
            total_users:
              this.state.isUserCountUnlimited === true
                ? null
                : this.state.noOfTotalUsers,
            website: this.state.website,
            address: this.state.address,
            start_date: this.state.startDate,
            end_date:
              this.state.isAllowLifetime === true ? null : this.state.endDate,
            no_end_date: this.state.isAllowLifetime === true ? 1 : 0,
            contact_email: this.state.contactEmail,
            contact_phone: this.state.contactNumber,
            contact_person: this.state.contactPersonName,
            company_segment_ids: company_segments,
            allow_all_companies: isAllSegmentCheck,
            allow_all_segments: isAllSegmentCheck,
            company_ids: companies,
            segment_ids: segments,
            benchmarks: {
              allow_all: isAllBenchmarks,
              segment_id: benchmarkSegments,
              id: benchmarks,
            },
            market_forecasts: {
              allow_all: isAllMarketForecasts,
              segment_id: marketForecastSegments,
              id: marketForecasts,
            },
            market_landscapes: {
              allow_all: isAllMarketLandscapes,
              segment_id: marketLandscapeSegments,
              id: marketLandscapes,
            },
            special_reports: {
              allow_all: isAllSpecialReports,
              segment_id: specialReportsSegments,
              id: specialReports,
            },
            customer_study: {
              allow_all: isAllCustomerStudies,
              segment_id: customerStudiesSegments,
              id: customerStudies,
            },
            ecosystems: {
              allow_all: isAllEcosystems,
              segment_id: ecosystemSegments,
              id: ecosystems,
            },
            no_of_reports_purchased: numberOfReportsPurchased,
            is_set_password: this.state.is_set_password ?? 0,
            is_sso: this.state.is_sso ?? 0,
          };
          // return false;
          if (clientId === null) {
            //create
            ClientServices.addClient(prepareObject)
              .then((res) => {
                this.saveClientSuccess();
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error
                      ? error.data.error
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
              });
          } else {
            ClientServices.editClient(clientId, prepareObject)
              .then((res) => {
                this.saveClientSuccess();
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error
                      ? error.data.error
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
              });
          }
        }
      }
    );
  };
  removeClient = () => {
    ClientServices.removeClient(this.props.match.params.id)
      .then((res) => {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">Success</h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.CLIENT.REMOVE.SUCCESS}
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "success-modal",
        });
        setTimeout(
          () =>
            this.props.history.push({
              pathname: formatRoute(
                constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE
              ),
            }),
          1500
        );
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  saveClientSuccess = () => {
    let clientId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Success</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            {clientId === null
              ? constants.CLIENT.CREATE.SUCCESS
              : constants.CLIENT.EDIT.SUCCESS}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: false, id: null },
      dailogModalStyleType: "success-modal",
    });
    setTimeout(
      () =>
        this.props.history.push({
          pathname: formatRoute(constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE),
        }),
      1500
    );
  };
  dailogModalAccept = (config) => {
    this.setState(
      {
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
      },
      () => {
        switch (config.type) {
          case "remove_company":
            this.removeCompany(config.id, config.companyId);
            break;
          case "remove_segment":
            this.removeSegment(config.id);
            break;
          case "remove_benchmark_segment":
            this.removeBenchmarkSegment(config.id);
            break;
          case "remove_benchmark":
            this.removeBenchmark(config.id);
            break;
          case "remove_marketforecast_segment":
            this.removeMarketForecastSegment(config.id);
            break;
          case "remove_marketforecast":
            this.removeMarketForecast(config.id);
            break;
          case "remove_marketlandscape_segment":
            this.removeMarketLandscapeSegment(config.id);
            break;
          case "remove_marketlandscape":
            this.removeMarketLandscape(config.id);
            break;
          case "remove_client":
            this.removeClient();
            break;
          case "remove_special_report":
            this.removeSpecialReport(config.id);
            break;
          case "remove_special_report_segment":
            this.removeSpecialReportSegment(config.id);
            break;
          case "remove_customer_studies":
            this.removeCustomerStudies(config.id);
            break;
          case "remove_customer_studies_segment":
            this.removeCustomerStudiesSegment(config.id);
            break;
          case "remove_ecosystem":
            this.removeEcosystem(config.id);
            break;
          case "remove_ecosystem_segment":
            this.removeEcosystemSegment(config.id);
            break;
          default:
            break;
        }
      }
    );
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  resetForm = () => {
    let clientId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    this.handleMarketForecastChange([]);
    this.handleBenchmarkChange([]);
    this.handleMarketLandscapeChange([]);
    this.handleSpecialReportChange([]);
    this.handleCustomerStudiesChange([]);
    this.handleEcosystemChange([]);
    this.setState({
      ...this.state,
      clientName: "",
      contactPersonName: "",
      contactEmail: "",
      contactNumber: "",
      website: "",
      address: "",
      startDate: "",
      endDate: "",
      isAllowLifetime: false,
      noOfTotalUsers: 0,
      isUserCountUnlimited: false,
      isAllCompany: false,
      isAllSegment: false,
      isAllBenchmark: false,
      isAllBenchmarkSegment: false,
      isAllMarketForecast: false,
      isAllMarketForecastSegment: false,
      isAllMarketLandscape: false,
      isAllMarketLandscapeSegment: false,
      isAllCustomerStudies: false,
      isAllCustomerStudiesSegment: false,
      isAllEcosystem: false,
      isAllEcosystemSegment: false,
      companySegments: [],
      segmentList: this.state.segmentList.map((s) => {
        if (s.is_selected === true) {
          s.is_selected = false;
        }
        return s;
      }),
      unselectedsegmentList: this.state.segmentList,
      unselectedmarketforecastList: this.state.marketForecastList,
      selectedMarketLandscapes: [],
      selectedMarketForecasts: [],
      selectedBenchmarks: [],
      segmentCompanies: [],
      selectedCustomerStudies: [],
      selectedEcosystems: [],
      formErrors: {
        ...this.state.formErrors,
        clientNameError: false,
        contactPersonNameError: false,
        contactEmailError: false,
        contactNumberError: false,
        startDateError: false,
        endDateError: false,
        noOfTotalUsersError: false,
      },
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      isFormSubmitted: false,
      apiError: null,
      specialReportsList: [],
      unselectedSpecialReportsList: [],
      unselectedCustomerStudiesList: [],
      unselectedEcosystemList: [],
      selectedSpecialReports: [],
      isAllSpecialReportsSegment: false,
      isAllSpecialReports: false,
    });
    if (clientId !== null) {
      this.prepopulateData(clientId);
    }
  };
  breadcrumbs = () => {
    let clientId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT.LIST.NAME,
        link: constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE,
        is_active: false,
      },
      {
        title: clientId
          ? constants.APPLICATION_ROUTE.CLIENT.EDIT.NAME
          : constants.APPLICATION_ROUTE.CLIENT.CREATE.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  groupBenchmarks = (benchmarks) => {
    let segments = benchmarks.map((b) => b.segmentId);
    segments = [...new Set(segments)];
    let groupedSegments = [];
    segments.forEach((s) => {
      groupedSegments.push({
        segmentId: s,
        segmentName: benchmarks.find((b) => b.segmentId === s).segmentName,
        benchmarks: benchmarks.filter((f) => f.segmentId === s),
      });
    });
    return groupedSegments;
  };

  groupMarketForecasts = (marketForecasts) => {
    let segments = marketForecasts.map((b) => b.segmentId);
    segments = [...new Set(segments)];
    let groupedSegments = [];
    segments.forEach((s) => {
      groupedSegments.push({
        segmentId: s,
        segmentName: marketForecasts.find((b) => b.segmentId === s).segmentName,
        marketForecasts: marketForecasts.filter((f) => f.segmentId === s),
      });
    });
    return groupedSegments;
  };

  groupMarketLandscapes = (marketLandscapes) => {
    let segments = marketLandscapes.map((b) => b.segmentId);
    segments = [...new Set(segments)];
    let groupedSegments = [];
    segments.forEach((s) => {
      groupedSegments.push({
        segmentId: s,
        segmentName: marketLandscapes.find((b) => b.segmentId === s)
          .segmentName,
        marketLandscapes: marketLandscapes.filter((f) => f.segmentId === s),
      });
    });
    return groupedSegments;
  };

  removeMarketLandscapeSegment = (sid) => {
    let finalObject = [...this.state.selectedMarketLandscapes];

    // Filter landscapes related to the segment ID
    let finalObjectfiltered = finalObject.filter((ob) => ob.segmentId === sid);

    // Extract IDs to be removed
    let ids = finalObjectfiltered.map((s) => s.marketLandscapeId);

    // Find corresponding landscapes in the unselected list and update `is_selected`
    let additionalMarketLandScape = this.state.unselectedMarketLandScapesList
      .filter((s) => ids.includes(s.id))
      .map((s) => {
        return { ...s, is_selected: false };
      });

    // Remove landscapes for the given segment from the selected list
    finalObject = finalObject.filter((ob) => ob.segmentId !== sid);

    // Ensure unique entries in `marketLandscapeList`
    const updatedMarketLandscapeList = [
      ...this.state.marketLandscapeList.filter(
        (existing) =>
          !additionalMarketLandScape.find((added) => added.id === existing.id)
      ),
      ...additionalMarketLandScape,
    ];

    // Update the state
    this.setState(
      {
        ...this.state,
        marketLandscapeList: updatedMarketLandscapeList,
        selectedMarketLandscapes: finalObject,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  removeMarketLandscape = (uid) => {
    // Find the landscape by ID in the unselected list and update `is_selected`
    let tempMarketLandScape = this.state.unselectedMarketLandScapesList
      .filter((s) => s.id === uid)
      .map((s) => {
        return { ...s, is_selected: false };
      });

    // Remove the landscape from the selected list
    let finalObject = [...this.state.selectedMarketLandscapes].filter(
      (ob) => ob.marketLandscapeId !== uid
    );

    // Ensure unique entries in `marketLandscapeList`
    const updatedMarketLandscapeList = [
      ...this.state.marketLandscapeList.filter(
        (existing) =>
          !tempMarketLandScape.find((added) => added.id === existing.id)
      ),
      ...tempMarketLandScape,
    ];

    // Update the state
    this.setState(
      {
        ...this.state,
        marketLandscapeList: updatedMarketLandscapeList,
        selectedMarketLandscapes: finalObject,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  handleMarketLandscapeSegmentChange = (e) => {
    let marketLandscapeSegmentsList = [
      ...this.state.marketLandscapeSegmentsList,
    ];
    marketLandscapeSegmentsList.forEach((s) => {
      s.is_selected = e.id === s.id ? true : false;
    });
    let isAllMarketLandscapeSegment = false;
    if (e.id === "all") {
      isAllMarketLandscapeSegment = true;
    } else {
      isAllMarketLandscapeSegment = false;
    }
    this.setState({
      marketLandscapeSegmentsList: marketLandscapeSegmentsList,
      isAllMarketLandscapeSegment: isAllMarketLandscapeSegment,
    });
  };

  handleMarketLandscapeChange = (e) => {
    let selectedMarketLandscapes = e.map((a) => a.id);
    let marketLandscapeList = [...this.state.marketLandscapeList];
    marketLandscapeList.forEach((c) => {
      c.is_selected = selectedMarketLandscapes.includes(c.id);
    });
    this.setState({
      marketLandscapeList: marketLandscapeList,
      selectedMarketLandScapesList: selectedMarketLandscapes,
    });
  };

  handleAddMarketLandscapes = () => {
    let selectedMarketLandscapes = [...this.state.selectedMarketLandscapes];
    let marketLandscapeList = [...this.state.marketLandscapeList];
    let marketLandscapeSegmentsList = [
      ...this.state.marketLandscapeSegmentsList,
    ];
    let isValid = false;
    if (
      this.state.isAllMarketLandscapeSegment &&
      (this.state.isAllMarketLandscape ||
        this.state.marketLandscapeList.filter((c) => c.is_selected === true)
          .length === 0)
    ) {
      selectedMarketLandscapes = [];
      selectedMarketLandscapes.push({
        uid: Math.random(),
        marketLandscapeId: "All",
        marketLandscapeName: "All",
        segmentName: "All",
        segmentId: "All",
      });
      isValid = true;
    } else {
      let selectedSegment = marketLandscapeSegmentsList.find(
        (s) => s.is_selected === true
      );
      if (selectedSegment && this.state.isAllMarketLandscape) {
        if (
          selectedMarketLandscapes.find(
            (sb) => sb.marketLandscapeId === "All" && sb.segmentId === "All"
          )
        ) {
        } else {
          selectedMarketLandscapes = selectedMarketLandscapes.filter(
            (s) => s.segmentId !== selectedSegment.id
          );
          selectedMarketLandscapes.push({
            uid: Math.random(),
            marketLandscapeId: "All",
            marketLandscapeName: "All",
            segmentName: selectedSegment.name,
            segmentId: selectedSegment.id,
          });
          isValid = true;
        }
      } else if (
        !this.state.isAllMarketLandscape &&
        this.state.marketLandscapeList.filter((c) => c.is_selected === true)
          .length > 0
      ) {
        if (
          selectedMarketLandscapes.find(
            (sb) => sb.marketLandscapeId === "All" && sb.segmentId === "All"
          )
        ) {
        } else {
          this.state.marketLandscapeList
            .filter((c) => c.is_selected === true)
            .forEach((b) => {
              if (
                !selectedMarketLandscapes.find(
                  (s) => s.marketLandscapeId === b.id
                ) &&
                !selectedMarketLandscapes.find(
                  (s) =>
                    s.segmentId === b.segment.id &&
                    s.marketLandscapeId === "All"
                )
              ) {
                selectedMarketLandscapes.push({
                  uid: Math.random(),
                  marketLandscapeId: b.id,
                  marketLandscapeName: b.name,
                  segmentName: b.segment.name,
                  segmentId: b.segment.id,
                });
                isValid = true;
              }
            });
        }
      }
    }
    if (isValid) {
      marketLandscapeList = marketLandscapeList.filter(
        (s) => !this.state.selectedMarketLandScapesList.includes(s.id)
      );
      marketLandscapeSegmentsList.forEach((b) => (b.is_selected = false));
      this.setState({
        selectedMarketLandscapes: selectedMarketLandscapes,
        isAllMarketLandscapeSegment: false,
        isAllMarketLandscape: false,
        marketLandscapeList: marketLandscapeList,
        marketLandscapeSegmentsList: marketLandscapeSegmentsList,
      });
    }
  };

  removeMarketLandscapeSegmentConfirmation = (type, uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {type === "segment"
            ? "Remove Marketlandscape Segment"
            : "Remove Marketlandscape"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {type === "segment"
              ? constants.CLIENT.REMOVE_MARKET_LANDSCAPE_SEGMENT_CONFIRM_MESSAGE
              : constants.CLIENT.REMOVE_MARKET_LANDSCAPE_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type:
          type === "segment"
            ? "remove_marketlandscape_segment"
            : "remove_marketlandscape",
        id: uid,
      },
      dailogModalStyleType: "danger-modal",
    });
  };
  getCompanySegmentReportsPurchased = (segment_id) => {
    let segments = [...this.state.segmentList];
    let count = 0;

    // if all segments selected count all companies specific to each segment
    if (segment_id == "all") {
      segments.forEach((s) => {
        if (s.id != "all" && s.companies && s.companies.length) {
          count = count + s.companies.length;
        }
      });
    } else {
      segments.forEach((s) => {
        if (s.id === segment_id && s.companies && s.companies.length) {
          count = count + s.companies.length;
        }
      });
    }

    return count;
  };

  getReportsPurchased = (list, id) => {
    let selectedItems = list.filter((b) => b.segment_id === id) || [];
    return selectedItems.length;
  };
  changeRoute = () => {
    this.props.history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE),
    });
  };
  removeClientConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.CLIENT.REMOVE_CLIENT_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_client" },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeSpecialReport = (uid) => {
    // Find the report by ID in the unselected list and update `is_selected`
    let tempSpecialReport = this.state.unselectedSpecialReportsList
      .filter((s) => s.id === uid)
      .map((s) => {
        return { ...s, is_selected: false };
      });

    // Remove the report from the selected list
    let finalObject = [...this.state.selectedSpecialReports].filter(
      (ob) => ob.specialReportId !== uid
    );

    // Ensure unique entries in `specialReportsList`
    const updatedSpecialReportsList = [
      ...this.state.specialReportsList.filter(
        (existing) =>
          !tempSpecialReport.find((added) => added.id === existing.id)
      ),
      ...tempSpecialReport,
    ];

    // Update the state
    this.setState(
      {
        ...this.state,
        specialReportsList: updatedSpecialReportsList,
        selectedSpecialReports: finalObject,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  groupSpecialReports = (specialReports) => {
    let segments = specialReports.map((b) => b.segmentId);
    segments = [...new Set(segments)];
    let groupedSegments = [];
    segments.forEach((s) => {
      groupedSegments.push({
        segmentId: s,
        segmentName: specialReports.find((b) => b.segmentId === s).segmentName,
        specialReports: specialReports.filter((f) => f.segmentId === s),
      });
    });
    return groupedSegments;
  };
  removeSpecialReportSegmentConfirmation = (type, uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {type === "segment"
            ? "Remove Special Report Segment"
            : "Remove Special Report"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {type === "segment"
              ? constants.CLIENT.REMOVE_MARKET_LANDSCAPE_SEGMENT_CONFIRM_MESSAGE
              : constants.CLIENT.REMOVE_SPECIAL_REPORT_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type:
          type === "segment"
            ? "remove_special_report_segment"
            : "remove_special_report",
        id: uid,
      },
      dailogModalStyleType: "danger-modal",
    });
  };
  handleSpecialReportChange = (e) => {
    let selectedSpecialReportsList = e.map((a) => a.id);
    let specialReportsList = [...this.state.specialReportsList];
    specialReportsList.forEach((c) => {
      c.is_selected = selectedSpecialReportsList.includes(c.id);
    });
    this.setState({ specialReportsList, selectedSpecialReportsList });
  };
  handleSpecialReportSegmentChange = (e) => {
    let specialReportsSegmentsList = [...this.state.specialReportsSegmentsList];
    specialReportsSegmentsList.forEach((s) => {
      s.is_selected = e.id === s.id ? true : false;
    });
    let isAllSpecialReportsSegment = false;
    if (e.id === "all") {
      isAllSpecialReportsSegment = true;
    }
    this.setState({ specialReportsSegmentsList, isAllSpecialReportsSegment });
  };

  // come back and check here for the "customerStudiesId"
  removeCustomerStudies = (uid) => {
    // Find the customer study by ID in the unselected list and update `is_selected`
    let tempCustomerStudies = this.state.unselectedCustomerStudiesList
      .filter((s) => s.id === uid)
      .map((s) => {
        return { ...s, is_selected: false };
      });

    // Remove the customer study from the selected list
    let finalObject = [...this.state.selectedCustomerStudies].filter(
      (ob) => ob.customerStudiesId !== uid
    );

    // Ensure unique entries in `customerStudiesList`
    const updatedCustomerStudiesList = [
      ...this.state.customerStudiesList.filter(
        (existing) =>
          !tempCustomerStudies.find((added) => added.id === existing.id)
      ),
      ...tempCustomerStudies,
    ];

    // Update the state
    this.setState(
      {
        ...this.state,
        customerStudiesList: updatedCustomerStudiesList,
        selectedCustomerStudies: finalObject,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  groupCustomerStudies = (customerStudies) => {
    let segments = customerStudies.map((b) => b.segmentId);
    segments = [...new Set(segments)];
    let groupedSegments = [];
    segments.forEach((s) => {
      groupedSegments.push({
        segmentId: s,
        segmentName: customerStudies.find((b) => b.segmentId === s).segmentName,
        customerStudies: customerStudies.filter((f) => f.segmentId === s),
      });
    });
    return groupedSegments;
  };
  removeCustomerStudiesSegmentConfirmation = (type, uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {type === "segment"
            ? "Remove Customer Studies Segment"
            : "Remove Customer Studies"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {type === "segment"
              ? constants.CLIENT.REMOVE_MARKET_LANDSCAPE_SEGMENT_CONFIRM_MESSAGE
              : constants.CLIENT.REMOVE_CUSTOMER_STUDIES_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type:
          type === "segment"
            ? "remove_customer_studies_segment"
            : "remove_customer_studies",
        id: uid,
      },
      dailogModalStyleType: "danger-modal",
    });
  };
  handleCustomerStudiesChange = (e) => {
    let selectedCustomerStudiesList = e.map((a) => a.id);
    let customerStudiesList = [...this.state.customerStudiesList];
    customerStudiesList.forEach((c) => {
      c.is_selected = selectedCustomerStudiesList.includes(c.id);
    });
    this.setState({ customerStudiesList, selectedCustomerStudiesList });
  };
  handleCustomerStudiesSegmentChange = (e) => {
    let customerStudiesSegmentsList = [
      ...this.state.customerStudiesSegmentsList,
    ];
    customerStudiesSegmentsList.forEach((s) => {
      s.is_selected = e.id === s.id ? true : false;
    });
    let isAllCustomerStudiesSegment = false;
    if (e.id === "all") {
      isAllCustomerStudiesSegment = true;
    }
    this.setState({ customerStudiesSegmentsList, isAllCustomerStudiesSegment });
  };
  // come back and check here for the "customerStudiesId"
  removeEcosystem = (uid) => {
    // Find the ecosystem by ID in the unselected list and update `is_selected`
    let tempEcosystem = this.state.unselectedEcosystemList
      .filter((s) => s.id === uid)
      .map((s) => {
        return { ...s, is_selected: false };
      });

    // Remove the ecosystem from the selected list
    let finalObject = [...this.state.selectedEcosystems].filter(
      (ob) => ob.ecosystemId !== uid
    );

    // Ensure unique entries in `ecosystemList`
    const updatedEcosystemList = [
      ...this.state.ecosystemList.filter(
        (existing) => !tempEcosystem.find((added) => added.id === existing.id)
      ),
      ...tempEcosystem,
    ];

    // Update the state
    this.setState(
      {
        ...this.state,
        ecosystemList: updatedEcosystemList,
        selectedEcosystems: finalObject,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  groupEcosystem = (ecosystem) => {
    let segments = ecosystem.map((b) => b.segmentId);
    segments = [...new Set(segments)];
    let groupedSegments = [];
    segments.forEach((s) => {
      groupedSegments.push({
        segmentId: s,
        segmentName: ecosystem.find((b) => b.segmentId === s).segmentName,
        ecosystem: ecosystem.filter((f) => f.segmentId === s),
      });
    });
    return groupedSegments;
  };
  removeEcosystemSegmentConfirmation = (type, uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {type === "segment" ? "Remove Ecosystem Segment" : "Remove Ecosystem"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {type === "segment"
              ? constants.CLIENT.REMOVE_ECOSYSTEM_SEGMENT_CONFIRM_MESSAGE
              : constants.CLIENT.REMOVE_ECOSYSTEM_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type:
          type === "segment" ? "remove_ecosystem_segment" : "remove_ecosystem",
        id: uid,
      },
      dailogModalStyleType: "danger-modal",
    });
  };
  handleEcosystemChange = (e) => {
    let selectedEcosystemList = e.map((a) => a.id);
    let ecosystemList = [...this.state.ecosystemList];
    ecosystemList.forEach((c) => {
      c.is_selected = selectedEcosystemList.includes(c.id);
    });
    this.setState({ ecosystemList, selectedEcosystemList });
  };
  handleEcosystemSegmentChange = (e) => {
    let ecosystemSegmentsList = [...this.state.ecosystemSegmentsList];
    ecosystemSegmentsList.forEach((s) => {
      s.is_selected = e.id === s.id ? true : false;
    });
    let isAllEcosystemSegment = false;
    if (e.id === "all") {
      isAllEcosystemSegment = true;
    }
    this.setState({ ecosystemSegmentsList, isAllEcosystemSegment });
  };

  // This is for handling number of users seat allowences
  handleNumberInputChange = (e) => {
    let value = e.target.value;
    this.setState(
      {
        noOfTotalUsers: value,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  render() {
    const segmentList = this.state.segmentList;
    let segments = segmentList;
    let companies = segments.filter(
      (segment) => segment.is_selected === true
    )[0]
      ? segments.filter((segment) => segment.is_selected === true)[0].companies
      : [];
    let clientId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let segmentSelected = segments.find(
      (segment) => segment.is_selected === true
    )
      ? true
      : false;
    let benchmarkSegmentsList = this.state.benchmarkSegmentsList;
    let benchmarkList = this.state.benchmarkList;
    let benchmarkSegmentSelected = benchmarkSegmentsList.find(
      (segment) => segment.is_selected === true
    )
      ? true
      : false;
    if (benchmarkSegmentSelected) {
      if (!this.state.isAllBenchmarkSegment)
        benchmarkList = benchmarkList.filter(
          (b) =>
            b.segment_id ===
            benchmarkSegmentsList.find(
              (segment) => segment.is_selected === true
            ).id
        );
    }
    let groupedBenchmarks = this.groupBenchmarks(this.state.selectedBenchmarks);

    let marketForecastSegmentsList = this.state.marketForecastSegmentsList;
    let marketForecastList = this.state.marketForecastList;
    let marketForecastSegmentSelected = marketForecastSegmentsList.find(
      (segment) => segment.is_selected === true
    )
      ? true
      : false;
    if (marketForecastSegmentSelected) {
      if (!this.state.isAllMarketForecastSegment)
        marketForecastList = marketForecastList.filter(
          (b) =>
            b.segment.id ===
            marketForecastSegmentsList.find(
              (segment) => segment.is_selected === true
            ).id
        );
    }
    let groupedMarketForecasts = this.groupMarketForecasts(
      this.state.selectedMarketForecasts
    );

    let validCompanySegmentMapping =
      segmentSelected &&
      (companies.find((c) => c.is_selected === true) ||
        this.state.isAllCompany);

    let validBenchmarkMapping =
      // this.state.isAllBenchmarkSegment ||
      (benchmarkSegmentSelected && this.state.isAllBenchmark) ||
      (benchmarkSegmentSelected &&
        benchmarkList.find((c) => c.is_selected === true)) ||
      (!this.state.isAllBenchmarkSegment &&
        benchmarkList.find((c) => c.is_selected === true));

    let validMarketForecastMapping =
      // this.state.isAllMarketForecastSegment ||
      (marketForecastSegmentSelected && this.state.isAllMarketForecast) ||
      (marketForecastSegmentSelected &&
        marketForecastList.find((c) => c.is_selected === true)) ||
      (!this.state.isAllMarketForecastSegment &&
        marketForecastList.find((c) => c.is_selected === true));

    let marketLandscapeList = this.state.marketLandscapeList;
    let marketLandscapeSegmentsList = this.state.marketLandscapeSegmentsList;
    let marketLandscapeSegmentSelected = marketLandscapeSegmentsList.find(
      (segment) => segment.is_selected === true
    )
      ? true
      : false;
    if (marketLandscapeSegmentSelected) {
      if (!this.state.isAllMarketLandscapeSegment)
        marketLandscapeList = marketLandscapeList.filter(
          (b) =>
            b.segment_id ===
            marketLandscapeSegmentsList.find(
              (segment) => segment.is_selected === true
            ).id
        );
    }
    let validMarketLandscapeMapping =
      // this.state.isAllMarketLandscapeSegment ||
      (marketLandscapeSegmentSelected && this.state.isAllMarketLandscape) ||
      (marketLandscapeSegmentSelected &&
        marketLandscapeList.find((c) => c.is_selected === true)) ||
      (!this.state.isAllMarketLandscapeSegment &&
        marketLandscapeList.find((c) => c.is_selected === true));
    let groupedMarketLandscapes = this.groupMarketLandscapes(
      this.state.selectedMarketLandscapes
    );

    let specialReportsList = this.state.specialReportsList;
    let specialReportsSegmentsList = this.state.specialReportsSegmentsList;
    let specialReportsSegmentSelected = specialReportsSegmentsList.find(
      (segment) => segment.is_selected === true
    )
      ? true
      : false;
    if (specialReportsSegmentSelected) {
      if (!this.state.isAllSpecialReportsSegment)
        specialReportsList = specialReportsList.filter(
          (b) =>
            b.segment_id ===
            specialReportsSegmentsList.find(
              (segment) => segment.is_selected === true
            ).id
        );
    }
    let validSpecialReportMapping =
      // this.state.isAllSpecialReportsSegment ||
      (specialReportsSegmentSelected && this.state.isAllSpecialReports) ||
      (specialReportsSegmentSelected &&
        specialReportsList.find((c) => c.is_selected === true)) ||
      (!this.state.isAllSpecialReportsSegment &&
        specialReportsList.find((c) => c.is_selected === true));
    let groupedSpecialReports = this.groupSpecialReports(
      this.state.selectedSpecialReports
    );

    let customerStudiesList = this.state.customerStudiesList;
    let customerStudiesSegmentsList = this.state.customerStudiesSegmentsList;
    let customerStudiesSegmentSelected = customerStudiesSegmentsList.find(
      (segment) => segment.is_selected === true
    )
      ? true
      : false;
    if (customerStudiesSegmentSelected) {
      if (!this.state.isAllCustomerStudiesSegment)
        customerStudiesList = customerStudiesList.filter(
          (b) =>
            b.segment_id ===
            customerStudiesSegmentsList.find(
              (segment) => segment.is_selected === true
            ).id
        );
    }
    let validCustomerStudiesMapping =
      // this.state.isAllCustomerStudiesSegment ||
      (customerStudiesSegmentSelected && this.state.isAllCustomerStudies) ||
      (customerStudiesSegmentSelected &&
        customerStudiesList.find((c) => c.is_selected === true)) ||
      (!this.state.isAllCustomerStudiesSegment &&
        customerStudiesList.find((c) => c.is_selected === true));
    let groupedCustomerStudies = this.groupCustomerStudies(
      this.state.selectedCustomerStudies
    );
    let ecosystemList = this.state.ecosystemList;
    let ecosystemSegmentsList = this.state.ecosystemSegmentsList;
    let ecosystemSegmentSelected = ecosystemSegmentsList.find(
      (segment) => segment.is_selected === true
    )
      ? true
      : false;
    if (ecosystemSegmentSelected) {
      if (!this.state.isAllEcosystemSegment)
        ecosystemList = ecosystemList.filter(
          (b) =>
            b.segment_id ===
            ecosystemSegmentsList.find(
              (segment) => segment.is_selected === true
            ).id
        );
    }
    let validecosystemMapping =
      // this.state.isAllecosystemSegment ||
      (ecosystemSegmentSelected && this.state.isAllEcosystem) ||
      (ecosystemSegmentSelected &&
        ecosystemList.find((c) => c.is_selected === true)) ||
      (!this.state.isAllEcosystemSegment &&
        ecosystemList.find((c) => c.is_selected === true));
    let groupedEcosystem = this.groupEcosystem(this.state.selectedEcosystems);

    return (
      <aside className="main_content" id="main_content">
        <div className="m-3 mb-0 bg-white px-3 br_8 main_content_height">
          <div className="manage-client">
            <div className="">
              <div className={"add-edit-form-card"}>
                <form className={"form-wrapper"}>
                  {this.state.apiError !== null && (
                    <Notification
                      color={"danger"}
                      message={this.state.apiError}
                    />
                  )}
                  <div className="card border-0">
                    <div className="card-header title-cancel-header d-flex align-items-center">
                      <h3 className="text-darkblue2 mb-0 font-weight-bold">
                        {clientId === null
                          ? constants.APPLICATION_ROUTE.CLIENT.CREATE
                              .HEADER_TITLE
                          : constants.APPLICATION_ROUTE.CLIENT.EDIT
                              .HEADER_TITLE}
                      </h3>
                      <div className="ml-auto manage-delete-button">
                        {this.props.match.params.id && (
                          <AclAction
                            type={constants.ACL.PERMISSION_KEY}
                            entity={constants.ACL.BENCHMARK_RESOURCE.TITLE}
                            action={
                              constants.ACL.BENCHMARK_RESOURCE.ACTIONS.DELETE
                            }
                          >
                            <button
                              tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                              type="button"
                              className="btn btn-custom btn-danger btn-no-background icon-text-btn title-btn d-inline-flex align-items-center px-2 font-weight-semibold"
                              onClick={this.removeClientConfirmationHandler}
                            >
                              <span className="mt-2px">Delete Client</span>
                              <span className="material-icons icon-fs-16 ml-1">
                                delete
                              </span>
                            </button>
                          </AclAction>
                        )}
                      </div>
                      <span
                        className="material-icons-round text-darkblue2 ml-4 cursor-pointer"
                        onClick={this.changeRoute}
                      >
                        close
                      </span>
                    </div>
                    <div className="card-body">
                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">
                          Client Information
                        </h5>
                      </div>
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className={"row"}>
                              <div className="col-sm-12 form-group position-relative">
                                <label htmlFor="clientName">
                                  Client Name{" "}
                                  <span className={"mandatory"}>*</span>
                                </label>
                                <input
                                  disabled={this.state.isTBRClient}
                                  type="text"
                                  className={`form-control ${
                                    this.state.formErrors.clientNameError ===
                                    true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors.clientNameError ===
                                    false
                                      ? ""
                                      : ""
                                  }`}
                                  id="clientName"
                                  placeholder="Client Name"
                                  value={this.state.clientName}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors.clientNameError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6 form-group position-relative">
                                <label htmlFor="contactPersonName">
                                  Contact Person{" "}
                                  <span className={"mandatory"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.formErrors
                                      .contactPersonNameError === true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors
                                      .contactPersonNameError === false
                                      ? ""
                                      : ""
                                  }`}
                                  id="contactPersonName"
                                  placeholder="Contact Person"
                                  value={this.state.contactPersonName}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors
                                  .contactPersonNameError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6 form-group position-relative">
                                <label htmlFor="contactEmail">
                                  Contact Email{" "}
                                  <span className={"mandatory"}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.formErrors.contactEmailError ===
                                    true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors.contactEmailError ===
                                    false
                                      ? ""
                                      : ""
                                  }`}
                                  id="contactEmail"
                                  placeholder="Contact Email (example@domain.com)"
                                  value={this.state.contactEmail}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors.contactEmailError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.INVALID_EMAIL}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6 form-group position-relative">
                                <label
                                  id="clientnamelabel"
                                  htmlFor="contactNumber"
                                >
                                  Contact No.
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.formErrors.contactNumberError ===
                                    true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors.contactNumberError ===
                                    false
                                      ? ""
                                      : ""
                                  }`}
                                  id="contactNumber"
                                  placeholder="Contact No. (9999999999)"
                                  value={this.state.contactNumber}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors.contactNumberError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.INVALID_CONTACT_NUMBER}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6 form-group position-relative">
                                <label htmlFor="website">Website</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.formErrors.websiteError === true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors.websiteError === false
                                      ? ""
                                      : ""
                                  }`}
                                  id="website"
                                  placeholder="Website (www.example.com)"
                                  value={this.state.website}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors.websiteError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-12 form-group position-relative">
                                <label htmlFor="address">Address</label>
                                <textarea
                                  className={`form-control ${
                                    this.state.formErrors.addressError === true
                                      ? "is-invalid"
                                      : ""
                                  } ${
                                    this.state.formErrors.addressError === false
                                      ? ""
                                      : ""
                                  }`}
                                  id="address"
                                  placeholder="Address"
                                  value={this.state.address}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.formErrors.addressError && (
                                  <div className="invalid-tooltip">
                                    {constants.FORM.MANDATORY_FIELD}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className={"row"}>
                              <div className="col-sm-6 form-group position-relative">
                                <label htmlFor="startDate">
                                  Start Date{" "}
                                  <span className={"mandatory"}>*</span>
                                </label>
                                <div id="startDate">
                                  <CustomDatePicker
                                    className={`form-control border-0 p-0 no-border ${
                                      this.state.formErrors.startDateError ===
                                      true
                                        ? "is-invalid"
                                        : ""
                                    } ${
                                      this.state.formErrors.startDateError ===
                                      false
                                        ? ""
                                        : ""
                                    }`}
                                    date={this.state.startDate}
                                    id={"startDate"}
                                    onChangeHandler={this.dateOnChangeHandler}
                                  />
                                  {this.state.formErrors.startDateError && (
                                    <div className="invalid-tooltip">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-6 form-group position-relative">
                                <label htmlFor="endDate">
                                  End Date{" "}
                                  <span className={"mandatory"}>*</span>
                                </label>
                                <div
                                  className={
                                    "d-flex flex-column flex-sm-row align-items-center"
                                  }
                                >
                                  <div className="mr-sm-2 mb-2px d-flex align-items-center w-100 w-sm-auto">
                                    <input
                                      className="form-check-input custom-checkbox-new ml-0 mt-0"
                                      type="checkbox"
                                      id="isAllowLifetime"
                                      value="1"
                                      checked={this.state.isAllowLifetime}
                                      onChange={this.handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label ml-1"
                                      htmlFor="isAllowLifetime"
                                    >
                                      Allow for lifetime
                                    </label>
                                  </div>
                                  <div className="flex-grow-1 w-100 w-sm-auto">
                                    <div id="endDate">
                                      <CustomDatePicker
                                        className={`form-control border-0 p-0 ${
                                          this.state.formErrors.endDateError ===
                                          true
                                            ? "is-invalid"
                                            : ""
                                        } ${
                                          this.state.formErrors.endDateError ===
                                          false
                                            ? ""
                                            : ""
                                        }`}
                                        date={this.state.endDate}
                                        id={"endDate"}
                                        onChangeHandler={
                                          this.dateOnChangeHandler
                                        }
                                        isDisabled={this.state.isAllowLifetime}
                                        min={this.state.startDate}
                                      />
                                      {this.state.formErrors.endDateError && (
                                        <div className="invalid-tooltip">
                                          {constants.FORM.MANDATORY_FIELD}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={"row"}>
                              <div className="col-sm-6 form-group position-relative">
                                <label htmlFor="noOfTotalUsers">
                                  No of Total Users{" "}
                                  <span className={"mandatory"}>*</span>
                                </label>
                                <div
                                  className={
                                    "d-flex flex-column flex-sm-row align-items-start"
                                  }
                                >
                                  <div className="mr-sm-2 d-flex align-items-center w-100 w-sm-auto mt-2">
                                    <input
                                      className="form-check-input custom-checkbox-new ml-0 mt-0"
                                      type="checkbox"
                                      id="isUserCountUnlimited"
                                      value="1"
                                      checked={this.state.isUserCountUnlimited}
                                      onChange={this.handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label ml-1"
                                      htmlFor="isUserCountUnlimited"
                                    >
                                      Unlimited
                                    </label>
                                  </div>
                                  <div
                                    style={{
                                      padding: "4px",
                                      minWidth: "140px",
                                    }}
                                  >
                                    <div
                                      className={`${
                                        this.state.formErrors
                                          .noOfTotalUsersError === true
                                          ? "is-invalidDropDown"
                                          : ""
                                      }`}
                                      id="noOfTotalUsers"
                                    >
                                      <input
                                        type="number"
                                        className={`form-control ${
                                          this.state.formErrors
                                            .noOfTotalUsersError === true
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        id="noOfTotalUsers"
                                        value={this.state.noOfTotalUsers}
                                        onChange={this.handleNumberInputChange}
                                        disabled={
                                          this.state.isUserCountUnlimited
                                        }
                                      />
                                    </div>
                                    {this.state.formErrors
                                      .noOfTotalUsersError && (
                                      <div className="invalid-tooltip">
                                        {constants.FORM.SELECT_DROPDOWN}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {process.env.REACT_APP_ENV !== "production" && (
                                <div className="col-sm-6 form-group position-relative">
                                  <div className="d-flex">
                                    <label
                                      className={`${
                                        this.state.formErrors
                                          .userInviteOptionError === true
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    >
                                      Invitation Email login options{" "}
                                      <span className={"mandatory"}>*</span>
                                    </label>
                                    {this.state.formErrors
                                      .userInviteOptionError && (
                                      <>
                                        <span
                                          className="material-icons-round icon-fs-16 cursor-pointer"
                                          style={{ color: "red" }}
                                        >
                                          info
                                        </span>
                                        <span
                                          className="ml-1"
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Please select at least one option
                                        </span>
                                      </>
                                    )}
                                  </div>
                                  <div className="d-flex flex-column flex-sm-row align-items-center mt-2">
                                    <div className="mr-sm-2 d-flex align-items-center w-100 w-sm-auto">
                                      <input
                                        className="form-check-input custom-checkbox-new ml-0 mt-0"
                                        type="checkbox"
                                        id="is_set_password"
                                        checked={this.state.is_set_password}
                                        onChange={
                                          this.handleCheckboxInviteOption
                                        }
                                      />
                                      <label
                                        className="form-check-label ml-1"
                                        htmlFor="is_set_password"
                                        style={{ fontSize: "12px" }}
                                      >
                                        Set Password
                                      </label>
                                    </div>
                                    <div className="mr-sm-2 d-flex align-items-center w-100 w-sm-auto">
                                      <input
                                        className="form-check-input custom-checkbox-new ml-0 mt-0"
                                        type="checkbox"
                                        id="is_sso"
                                        checked={this.state.is_sso}
                                        onChange={
                                          this.handleCheckboxInviteOption
                                        }
                                      />
                                      <label
                                        className="form-check-label ml-1"
                                        htmlFor="is_sso"
                                        style={{ fontSize: "12px" }}
                                      >
                                        SSO
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">
                          Companies / Segments
                        </h5>
                      </div>
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className={"row"}>
                              <div className="col-lg-6 col-sm-6 mb-2 position-relative">
                                <label htmlFor="segments">Segments</label>
                                <div className="custom-combobox-list">
                                  <CustomDropdownList
                                    data={segments}
                                    handleChange={this.handleSegmentChange}
                                    placeholder={"Select Segment"}
                                    isDisabled={
                                      this.state.isAllCompany &&
                                      this.state.isAllSegment
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-6 mb-2 position-relative">
                                <label htmlFor="companies">
                                  Company
                                  <span style={{ marginLeft: "25px" }}>
                                    <input
                                      disabled={!segmentSelected}
                                      className="form-check-input custom-checkbox-new"
                                      type="checkbox"
                                      id="isAllCompany"
                                      value="1"
                                      checked={this.state.isAllCompany}
                                      onChange={this.handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label ml-1"
                                      htmlFor="isAllCompany"
                                    >
                                      All
                                    </label>
                                  </span>
                                </label>
                                <div className="custom-multiselect">
                                  <CustomMultiSelect
                                    disabled={
                                      !segmentSelected ||
                                      this.state.isAllCompany
                                    }
                                    data={companies}
                                    handleChange={this.handleCompanyChange}
                                    placeholder={"Select Companies"}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 text-right">
                                <button
                                  disabled={
                                    !validCompanySegmentMapping ||
                                    this.state.isTBRClient
                                  }
                                  type="button"
                                  className="btn btn-md btn-primary modal-btn"
                                  onClick={this.handleAddSegmentCompany}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                            <div className="custom-table-block custom-table-block-2 nowrap-table-block th-nowrap center-th-align my-4">
                              <div className="responsive-table">
                                <table
                                  className="table table-md"
                                  cellSpacing="0"
                                  width="100%"
                                >
                                  <colgroup className="row col-sm-12">
                                    <col className="col-sm-2" />
                                    <col className="col-sm-8" />
                                    <col className="col-sm-2" />
                                  </colgroup>
                                  <thead>
                                    <tr>
                                      <th>Segments</th>
                                      <th>Company</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.segmentCompanies.map((seg) => (
                                      <tr key={seg.uid}>
                                        <td>{seg.name}</td>
                                        <td>
                                          {seg.companies.map((com) => (
                                            <span
                                              key={`${seg.uid}_${com.id}`}
                                              className={
                                                "segment-tag align-items-center"
                                              }
                                            >
                                              {com.name}
                                              {com.id === "all" ||
                                              com.id === "-" ? null : (
                                                <span
                                                  className="material-icons-round icon-fs-16 text-grey mt-2px ml-2 cursor-pointer"
                                                  onClick={() => {
                                                    !this.state.isTBRClient &&
                                                      this.removeCompanyConfirmation(
                                                        seg.uid,
                                                        com.id
                                                      );
                                                  }}
                                                >
                                                  clear
                                                </span>
                                              )}
                                            </span>
                                          ))}
                                        </td>
                                        <td className="text-center">
                                          <span
                                            className="material-icons-outlined text-grey icon-fs-20 cursor-pointer"
                                            disabled={this.state.isTBRClient}
                                            onClick={() => {
                                              !this.state.isTBRClient &&
                                                this.removeSegmentConfirmation(
                                                  seg.uid
                                                );
                                            }}
                                          >
                                            delete
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                    {this.state.segmentCompanies.length ===
                                      0 && (
                                      <tr>
                                        <td
                                          className={"text-center"}
                                          colSpan={3}
                                        >
                                          {
                                            constants.CLIENT.CREATE
                                              .NO_COMPANY_SEGMENTS_SELECTED
                                          }
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">
                          Benchmarks
                        </h5>
                      </div>
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className={"row"}>
                              <div className="col-lg-12 col-sm-12 form-group position-relative mb-2 mb-sm-0">
                                <div className={"row"}>
                                  <div className="col-lg-4 col-sm-4 ml-auto">
                                    <div className="custom-combobox-list">
                                      <CustomDropdownList
                                        data={benchmarkSegmentsList}
                                        handleChange={
                                          this.handleBenchmarkSegmentChange
                                        }
                                        placeholder={"Filter Segment"}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-sm-12 mb-2 position-relative">
                                <label htmlFor="benchmarks">
                                  <span style={{ marginLeft: "22px" }}>
                                    <input
                                      disabled={!benchmarkSegmentSelected}
                                      className="form-check-input custom-checkbox-new"
                                      type="checkbox"
                                      id="isAllBenchmark"
                                      value="1"
                                      checked={this.state.isAllBenchmark}
                                      onChange={this.handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label ml-1"
                                      htmlFor="isAllBenchmark"
                                    >
                                      All
                                    </label>
                                  </span>
                                </label>
                                <div className="custom-multiselect">
                                  <CustomMultiSelect
                                    data={benchmarkList}
                                    handleChange={this.handleBenchmarkChange}
                                    placeholder={"Select Benchmarks"}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 text-right">
                                <button
                                  disabled={
                                    !validBenchmarkMapping ||
                                    this.state.isTBRClient
                                  }
                                  type="button"
                                  className="btn btn-md btn-primary modal-btn"
                                  onClick={this.handleAddBenchmarks}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                            <div className="custom-table-block custom-table-block-2 nowrap-table-block th-nowrap center-th-align my-4">
                              <div className="responsive-table">
                                <table
                                  className="table table-md"
                                  cellSpacing="0"
                                  width="100%"
                                >
                                  <colgroup className="row col-sm-12">
                                    <col className="col-sm-2" />
                                    <col className="col-sm-8" />
                                    <col className="col-sm-2" />
                                  </colgroup>
                                  <thead>
                                    <tr>
                                      <th>Segment</th>
                                      <th>Benchmark</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {groupedBenchmarks.map((seg) => (
                                      <tr key={seg.segmentId}>
                                        <td>{seg.segmentName}</td>
                                        <td>
                                          {/* {seg.benchmarkName} */}
                                          {seg.benchmarks.map((com) => (
                                            <span
                                              key={`${com.uid}`}
                                              className={
                                                "segment-tag align-items-center"
                                              }
                                            >
                                              {com.benchmarkName}
                                              {com.benchmarkId === "All" ||
                                              com.benchmarkId === "-" ? null : (
                                                <span
                                                  className="material-icons-round icon-fs-16 text-grey mt-2px ml-2 cursor-pointer"
                                                  onClick={() => {
                                                    !this.state.isTBRClient &&
                                                      this.removeBenchmarkSegmentConfirmation(
                                                        "benchmark",
                                                        com.benchmarkId
                                                      );
                                                  }}
                                                >
                                                  clear
                                                </span>
                                              )}
                                            </span>
                                          ))}
                                        </td>
                                        <td className="text-center">
                                          <span
                                            className="material-icons-outlined text-grey icon-fs-20 cursor-pointer"
                                            disabled={this.state.isTBRClient}
                                            onClick={() => {
                                              !this.state.isTBRClient &&
                                                this.removeBenchmarkSegmentConfirmation(
                                                  "segment",
                                                  seg.segmentId
                                                );
                                            }}
                                          >
                                            delete
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                    {groupedBenchmarks.length === 0 && (
                                      <tr>
                                        <td
                                          className={"text-center"}
                                          colSpan={3}
                                        >
                                          {
                                            constants.CLIENT.CREATE
                                              .NO_BENCHMARKS_SELECTED
                                          }
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">
                          Market Forecasts
                        </h5>
                      </div>
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className={"row"}>
                              <div className="col-lg-12 col-sm-12 form-group position-relative mb-2 mb-sm-0">
                                <div className={"row"}>
                                  <div className="col-lg-4 col-sm-4 ml-auto">
                                    <div className="custom-combobox-list">
                                      <CustomDropdownList
                                        data={marketForecastSegmentsList}
                                        handleChange={
                                          this.handleMarketForecastSegmentChange
                                        }
                                        placeholder={"Filter Segment"}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-sm-12 mb-2 position-relative">
                                <label htmlFor="marketforecasts">
                                  <span style={{ marginLeft: "22px" }}>
                                    <input
                                      disabled={!marketForecastSegmentSelected}
                                      className="form-check-input custom-checkbox-new"
                                      type="checkbox"
                                      id="isAllMarketForecast"
                                      value="1"
                                      checked={this.state.isAllMarketForecast}
                                      onChange={this.handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label ml-1"
                                      htmlFor="isAllMarketForecast"
                                    >
                                      All
                                    </label>
                                  </span>
                                </label>
                                <div className="custom-multiselect">
                                  <CustomMultiSelect
                                    data={marketForecastList}
                                    handleChange={
                                      this.handleMarketForecastChange
                                    }
                                    placeholder={"Select Market Forecasts"}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 text-right">
                                <button
                                  disabled={
                                    !validMarketForecastMapping ||
                                    this.state.isTBRClient
                                  }
                                  type="button"
                                  className="btn btn-md btn-primary modal-btn"
                                  onClick={this.handleAddMarketForecasts}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                            <div className="custom-table-block custom-table-block-2 nowrap-table-block th-nowrap center-th-align my-4">
                              <div className="responsive-table">
                                <table
                                  className="table table-md"
                                  cellSpacing="0"
                                  width="100%"
                                >
                                  <colgroup className="row col-sm-12">
                                    <col className="col-sm-2" />
                                    <col className="col-sm-8" />
                                    <col className="col-sm-2" />
                                  </colgroup>
                                  <thead>
                                    <tr>
                                      <th>Segment</th>
                                      <th>Market Forecast</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {groupedMarketForecasts.map((seg) => (
                                      <tr key={seg.segmentId}>
                                        <td>{seg.segmentName}</td>
                                        <td>
                                          {/* {seg.benchmarkName} */}
                                          {seg.marketForecasts.map((com) => (
                                            <span
                                              key={`${com.uid}`}
                                              className={
                                                "segment-tag align-items-center"
                                              }
                                            >
                                              {com.marketForecastName}
                                              {com.marketForecastId === "All" ||
                                              com.marketForecastId ===
                                                "-" ? null : (
                                                <span
                                                  className="material-icons-round icon-fs-16 text-grey mt-2px ml-2 cursor-pointer"
                                                  onClick={() => {
                                                    !this.state.isTBRClient &&
                                                      this.removeMarketForecastSegmentConfirmation(
                                                        "marketForecast",
                                                        com.marketForecastId
                                                      );
                                                  }}
                                                >
                                                  clear
                                                </span>
                                              )}
                                            </span>
                                          ))}
                                        </td>
                                        <td className="text-center">
                                          <span
                                            className="material-icons-outlined text-grey icon-fs-20 cursor-pointer"
                                            disabled={this.state.isTBRClient}
                                            onClick={() => {
                                              !this.state.isTBRClient &&
                                                this.removeMarketForecastSegmentConfirmation(
                                                  "segment",
                                                  seg.segmentId
                                                );
                                            }}
                                          >
                                            delete
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                    {groupedMarketForecasts.length === 0 && (
                                      <tr>
                                        <td
                                          className={"text-center"}
                                          colSpan={3}
                                        >
                                          {
                                            constants.CLIENT.CREATE
                                              .NO_MARKET_FORECAST_SELECTED
                                          }
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">
                          Market Landscape
                        </h5>
                      </div>
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className={"row"}>
                              <div className="col-lg-12 col-sm-12 form-group position-relative mb-2 mb-sm-0">
                                <div className={"row"}>
                                  <div className="col-lg-4 col-sm-4 ml-auto">
                                    <div className="custom-combobox-list">
                                      <CustomDropdownList
                                        data={marketLandscapeSegmentsList}
                                        handleChange={
                                          this
                                            .handleMarketLandscapeSegmentChange
                                        }
                                        placeholder={"Filter Segment"}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-sm-12 mb-2 position-relative">
                                <label htmlFor="marketlandscapes">
                                  <span style={{ marginLeft: "22px" }}>
                                    <input
                                      disabled={!marketLandscapeSegmentSelected}
                                      className="form-check-input custom-checkbox-new"
                                      type="checkbox"
                                      id="isAllMarketLandscape"
                                      value="1"
                                      checked={this.state.isAllMarketLandscape}
                                      onChange={this.handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label ml-1"
                                      htmlFor="isAllMarketLandscape"
                                    >
                                      All
                                    </label>
                                  </span>
                                </label>
                                <div className="custom-multiselect">
                                  <CustomMultiSelect
                                    data={marketLandscapeList}
                                    handleChange={
                                      this.handleMarketLandscapeChange
                                    }
                                    placeholder={"Select Market Landscapes"}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 text-right">
                                <button
                                  disabled={
                                    !validMarketLandscapeMapping ||
                                    this.state.isTBRClient
                                  }
                                  type="button"
                                  className="btn btn-md btn-primary modal-btn"
                                  onClick={this.handleAddMarketLandscapes}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                            <div className="custom-table-block custom-table-block-2 nowrap-table-block th-nowrap center-th-align my-4">
                              <div className="responsive-table">
                                <table
                                  className="table table-md"
                                  cellSpacing="0"
                                  width="100%"
                                >
                                  <thead>
                                    <tr>
                                      <th>Segment</th>
                                      <th>Market Landscape</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {groupedMarketLandscapes.map((seg) => (
                                      <tr key={seg.segmentId}>
                                        <td>{seg.segmentName}</td>
                                        <td>
                                          {/* {seg.benchmarkName} */}
                                          {seg.marketLandscapes.map((com) => (
                                            <span
                                              key={`${com.uid}`}
                                              className={
                                                "segment-tag align-items-center"
                                              }
                                            >
                                              {com.marketLandscapeName}
                                              {com.marketLandscapeId ===
                                                "All" ||
                                              com.marketLandscapeId ===
                                                "-" ? null : (
                                                <span
                                                  className="material-icons-round icon-fs-16 text-grey mt-2px ml-2 cursor-pointer"
                                                  onClick={() => {
                                                    !this.state.isTBRClient &&
                                                      this.removeMarketLandscapeSegmentConfirmation(
                                                        "marketLandscape",
                                                        com.marketLandscapeId
                                                      );
                                                  }}
                                                >
                                                  clear
                                                </span>
                                              )}
                                            </span>
                                          ))}
                                        </td>
                                        <td className="text-center">
                                          <span
                                            className="material-icons-outlined text-grey icon-fs-20 cursor-pointer"
                                            disabled={this.state.isTBRClient}
                                            onClick={() => {
                                              !this.state.isTBRClient &&
                                                this.removeMarketLandscapeSegmentConfirmation(
                                                  "segment",
                                                  seg.segmentId
                                                );
                                            }}
                                          >
                                            delete
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                    {groupedMarketLandscapes.length === 0 && (
                                      <tr>
                                        <td
                                          className={"text-center"}
                                          colSpan={3}
                                        >
                                          {
                                            constants.CLIENT.CREATE
                                              .NO_MARKET_LANDSCAPE_SELECTED
                                          }
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">
                          Special Reports
                        </h5>
                      </div>
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className={"row"}>
                              <div className="col-lg-12 col-sm-12 form-group position-relative mb-2 mb-sm-0">
                                <div className={"row"}>
                                  <div className="col-lg-4 col-sm-4 ml-auto">
                                    <div className="custom-combobox-list">
                                      <CustomDropdownList
                                        data={specialReportsSegmentsList}
                                        handleChange={
                                          this.handleSpecialReportSegmentChange
                                        }
                                        placeholder={"Filter Segment"}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-sm-12 mb-2 position-relative">
                                <label htmlFor="marketlandscapes">
                                  <span style={{ marginLeft: "22px" }}>
                                    <input
                                      disabled={!specialReportsSegmentSelected}
                                      className="form-check-input custom-checkbox-new"
                                      type="checkbox"
                                      id="isAllSpecialReports"
                                      value="1"
                                      checked={this.state.isAllSpecialReports}
                                      onChange={this.handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label ml-1"
                                      htmlFor="isAllSpecialReports"
                                    >
                                      All
                                    </label>
                                  </span>
                                </label>
                                <div className="custom-multiselect">
                                  <CustomMultiSelect
                                    data={specialReportsList}
                                    handleChange={
                                      this.handleSpecialReportChange
                                    }
                                    placeholder={"Select Special Reports"}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 text-right">
                                <button
                                  disabled={
                                    !validSpecialReportMapping ||
                                    this.state.isTBRClient
                                  }
                                  type="button"
                                  className="btn btn-md btn-primary modal-btn"
                                  onClick={this.handleAddSpecialReports}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                            <div className="custom-table-block custom-table-block-2 nowrap-table-block th-nowrap center-th-align my-4">
                              <div className="responsive-table">
                                <table
                                  className="table table-md"
                                  cellSpacing="0"
                                  width="100%"
                                >
                                  <thead>
                                    <tr>
                                      <th>Segment</th>
                                      <th>Special Reports</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {groupedSpecialReports.map((seg) => (
                                      <tr key={seg.segmentId}>
                                        <td>{seg.segmentName}</td>
                                        <td>
                                          {seg.specialReports.map((com) => (
                                            <span
                                              key={`${com.uid}`}
                                              className={
                                                "segment-tag align-items-center"
                                              }
                                            >
                                              {com.specialReportName}
                                              {com.specailReportId === "All" ||
                                              com.specialReportId ===
                                                "-" ? null : (
                                                <span
                                                  className="material-icons-round icon-fs-16 text-grey mt-2px ml-2 cursor-pointer"
                                                  onClick={() => {
                                                    !this.state.isTBRClient &&
                                                      this.removeSpecialReportSegmentConfirmation(
                                                        "specialreport",
                                                        com.specialReportId
                                                      );
                                                  }}
                                                >
                                                  clear
                                                </span>
                                              )}
                                            </span>
                                          ))}
                                        </td>
                                        <td className="text-center">
                                          <span
                                            className="material-icons-outlined text-grey icon-fs-20 cursor-pointer"
                                            disabled={this.state.isTBRClient}
                                            onClick={() => {
                                              !this.state.isTBRClient &&
                                                this.removeSpecialReportSegmentConfirmation(
                                                  "segment",
                                                  seg.segmentId
                                                );
                                            }}
                                          >
                                            delete
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                    {groupedSpecialReports.length === 0 && (
                                      <tr>
                                        <td
                                          className={"text-center"}
                                          colSpan={3}
                                        >
                                          {
                                            constants.CLIENT.CREATE
                                              .NO_SPECIAL_REPORT_SELECTED
                                          }
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">
                          Customer Studies
                        </h5>
                      </div>
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className={"row"}>
                              <div className="col-lg-12 col-sm-12 form-group position-relative mb-2 mb-sm-0">
                                <div className={"row"}>
                                  <div className="col-lg-4 col-sm-4 ml-auto">
                                    <div className="custom-combobox-list">
                                      <CustomDropdownList
                                        data={customerStudiesSegmentsList}
                                        handleChange={
                                          this
                                            .handleCustomerStudiesSegmentChange
                                        }
                                        placeholder={"Filter Segment"}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-sm-12 mb-2 position-relative">
                                <label htmlFor="marketlandscapes">
                                  <span style={{ marginLeft: "22px" }}>
                                    <input
                                      disabled={!customerStudiesSegmentSelected}
                                      className="form-check-input custom-checkbox-new"
                                      type="checkbox"
                                      id="isAllCustomerStudies"
                                      value="1"
                                      checked={this.state.isAllCustomerStudies}
                                      onChange={this.handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label ml-1"
                                      htmlFor="isAllCustomerStudies"
                                    >
                                      All
                                    </label>
                                  </span>
                                </label>
                                <div className="custom-multiselect">
                                  <CustomMultiSelect
                                    data={customerStudiesList}
                                    handleChange={
                                      this.handleCustomerStudiesChange
                                    }
                                    placeholder={"Select Customer Studies"}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 text-right">
                                <button
                                  disabled={
                                    !validCustomerStudiesMapping ||
                                    this.state.isTBRClient
                                  }
                                  type="button"
                                  className="btn btn-md btn-primary modal-btn"
                                  onClick={this.handleAddCustomerStudies}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                            <div className="custom-table-block custom-table-block-2 nowrap-table-block th-nowrap center-th-align my-4">
                              <div className="responsive-table">
                                <table
                                  className="table table-md"
                                  cellSpacing="0"
                                  width="100%"
                                >
                                  <thead>
                                    <tr>
                                      <th>Segment</th>
                                      <th>Customer Studies</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {groupedCustomerStudies.map((seg) => (
                                      <tr key={seg.segmentId}>
                                        <td>{seg.segmentName}</td>
                                        <td>
                                          {seg.customerStudies.map((com) => (
                                            <span
                                              key={`${com.uid}`}
                                              className={
                                                "segment-tag align-items-center"
                                              }
                                            >
                                              {com.customerStudiesName}
                                              {com.customerStudiesId ===
                                                "All" ||
                                              com.customerStudiesId ===
                                                "-" ? null : (
                                                <span
                                                  className="material-icons-round icon-fs-16 text-grey mt-2px ml-2 cursor-pointer"
                                                  onClick={() => {
                                                    !this.state.isTBRClient &&
                                                      this.removeCustomerStudiesSegmentConfirmation(
                                                        "cutomerStudies",
                                                        com.customerStudiesId
                                                      );
                                                  }}
                                                >
                                                  clear
                                                </span>
                                              )}
                                            </span>
                                          ))}
                                        </td>
                                        <td className="text-center">
                                          <span
                                            className="material-icons-outlined text-grey icon-fs-20 cursor-pointer"
                                            disabled={this.state.isTBRClient}
                                            onClick={() => {
                                              !this.state.isTBRClient &&
                                                this.removeCustomerStudiesSegmentConfirmation(
                                                  "segment",
                                                  seg.segmentId
                                                );
                                            }}
                                          >
                                            delete
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                    {groupedCustomerStudies.length === 0 && (
                                      <tr>
                                        <td
                                          className={"text-center"}
                                          colSpan={3}
                                        >
                                          {
                                            constants.CLIENT.CREATE
                                              .NO_CUSTOMER_STUDIES_SELECTED
                                          }
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-body-header">
                        <h5 className="mb-0 font-weight-semibold">
                          Ecosystems
                        </h5>
                      </div>
                      <div className="form-field-wrapper">
                        <div className="row">
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className={"row"}>
                              <div className="col-lg-12 col-sm-12 form-group position-relative mb-2 mb-sm-0">
                                <div className={"row"}>
                                  <div className="col-lg-4 col-sm-4 ml-auto">
                                    <div className="custom-combobox-list">
                                      <CustomDropdownList
                                        data={ecosystemSegmentsList}
                                        handleChange={
                                          this.handleEcosystemSegmentChange
                                        }
                                        placeholder={"Filter Segment"}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-sm-12 mb-2 position-relative">
                                <label htmlFor="marketlandscapes">
                                  <span style={{ marginLeft: "22px" }}>
                                    <input
                                      disabled={!ecosystemSegmentSelected}
                                      className="form-check-input custom-checkbox-new"
                                      type="checkbox"
                                      id="isAllEcosystem"
                                      value="1"
                                      checked={this.state.isAllEcosystem}
                                      onChange={this.handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label ml-1"
                                      htmlFor="isAllEcosystem"
                                    >
                                      All
                                    </label>
                                  </span>
                                </label>
                                <div className="custom-multiselect">
                                  <CustomMultiSelect
                                    data={ecosystemList}
                                    handleChange={this.handleEcosystemChange}
                                    placeholder={"Select Ecosystems"}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 text-right">
                                <button
                                  disabled={
                                    !validecosystemMapping ||
                                    this.state.isTBRClient
                                  }
                                  type="button"
                                  className="btn btn-md btn-primary modal-btn"
                                  onClick={this.handleAddEcosystem}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                            <div className="custom-table-block custom-table-block-2 nowrap-table-block th-nowrap center-th-align my-4">
                              <div className="responsive-table">
                                <table
                                  className="table table-md"
                                  cellSpacing="0"
                                  width="100%"
                                >
                                  <thead>
                                    <tr>
                                      <th>Segment</th>
                                      <th>Ecosystem</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {groupedEcosystem.map((seg) => (
                                      <tr key={seg.segmentId}>
                                        <td>{seg.segmentName}</td>
                                        <td>
                                          {seg.ecosystem.map((com) => (
                                            <span
                                              key={`${com.uid}`}
                                              className={
                                                "segment-tag align-items-center"
                                              }
                                            >
                                              {com.ecosystemName}
                                              {com.ecosystemId === "All" ||
                                              com.ecosystemId === "-" ? null : (
                                                <span
                                                  className="material-icons-round icon-fs-16 text-grey mt-2px ml-2 cursor-pointer"
                                                  onClick={() => {
                                                    !this.state.isTBRClient &&
                                                      this.removeEcosystemSegmentConfirmation(
                                                        "ecosystem",
                                                        com.ecosystemId
                                                      );
                                                  }}
                                                >
                                                  clear
                                                </span>
                                              )}
                                            </span>
                                          ))}
                                        </td>
                                        <td className="text-center">
                                          <span
                                            className="material-icons-outlined text-grey icon-fs-20 cursor-pointer"
                                            disabled={this.state.isTBRClient}
                                            onClick={() => {
                                              !this.state.isTBRClient &&
                                                this.removeEcosystemSegmentConfirmation(
                                                  "segment",
                                                  seg.segmentId
                                                );
                                            }}
                                          >
                                            delete
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                    {groupedEcosystem.length === 0 && (
                                      <tr>
                                        <td
                                          className={"text-center"}
                                          colSpan={3}
                                        >
                                          {
                                            constants.CLIENT.CREATE
                                              .NO_ECOSYSTEM_SELECTED
                                          }
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-submit-buttons">
                        <div className={"row"}>
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className="text-right form-buttons">
                              <button
                                type="button"
                                className="btn btn-outline-primary modal-btn"
                                onClick={this.resetForm}
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary modal-btn"
                                onClick={this.handleSubmit}
                              >
                                {this.props.match.params.id ? `Update` : `Save`}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </aside>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    company_details: state.company_details,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    companies: (payload) => {
      dispatch(companies(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageClient);
