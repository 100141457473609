import React, { Component } from "react";
import Routes from "./Routes/Routes";
import * as Sentry from "@sentry/react";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <Routes />
      </div>
    );
  }
}

export default Sentry.withProfiler(App);
